/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useState } from 'react'
import p from 'prop-types'
import {
  Hidden,
  Popover,
  SwipeableDrawer,
  IconButton,
  Grid,
  Button,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FilterList as FilterIcon } from '@mui/icons-material'

const useStyles = makeStyles((theme) => {
  return {
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    overflow: {
      overflow: 'visible',
    },
    container: {
      padding: 8,
      [theme.breakpoints.up('md')]: { width: '22em' },
      [theme.breakpoints.down('md')]: { width: '100%' },
    },
    formContainer: {
      margin: 0,
    },
  }
})

export default function FilterContainer(props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  function renderContent() {
    return (
      <Grid container spacing={2} className={classes.container}>
        <Grid
          item
          container
          xs={12}
          spacing={2}
          className={classes.formContainer}
        >
          {props.children}
        </Grid>
        <Grid item xs={12} className={classes.actions}>
          {props.onReset && (
            <Button
              data-cy='filter-reset'
              onClick={props.onReset}
              style={{
                fontFamily: 'IBM Plex Sans, sans-serif',
                boxShadow: 'none',
                transform: 'none',
                backgroundColor: '#29707A',
                color: '#FFFFFF',
                marginRight: '2px',
                fontSize: '14px',
                fontWeight: '500',
                alignItems: 'flex-start',
                justifyContent: 'left',
                width: '140px',
                height: '40px',
                textTransform: 'capitalize',
                borderRadius: '0px',
              }}
            >
              Reset
            </Button>
          )}
          <Button
            data-cy='filter-done'
            onClick={() => setAnchorEl(null)}
            style={{
              fontFamily: 'IBM Plex Sans, sans-serif',
              boxShadow: 'none',
              transform: 'none',
              backgroundColor: '#4CDD84',
              color: '#042315',
              marginRight: '2px',
              fontSize: '14px',
              fontWeight: '500',
              alignItems: 'flex-start',
              justifyContent: 'left',
              width: '140px',
              height: '40px',
              textTransform: 'capitalize',
              borderRadius: '0px',
            }}
          >
            Done
          </Button>
        </Grid>
      </Grid>
    )
  }

  const { icon, iconButtonProps, anchorRef } = props
  return (
    <React.Fragment>
      <IconButton
        onClick={(e) => setAnchorEl(anchorRef ? anchorRef.current : e.target)}
        title={props.title}
        aria-expanded={Boolean(anchorEl)}
        {...iconButtonProps}
        size='large'
      >
        {icon}
      </IconButton>
      <Hidden mdDown>
        <Popover
          anchorEl={anchorEl}
          classes={{
            paper: classes.overflow,
          }}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          {renderContent()}
        </Popover>
      </Hidden>
      <Hidden mdUp>
        <SwipeableDrawer
          anchor='top'
          classes={{
            paper: classes.overflow,
          }}
          disableDiscovery
          disableSwipeToOpen
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          onOpen={() => {}}
        >
          {renderContent()}
        </SwipeableDrawer>
      </Hidden>
    </React.Fragment>
  )
}

FilterContainer.propTypes = {
  icon: p.node,
  // https://material-ui.com/api/icon-button/
  iconButtonProps: p.object,
  onReset: p.func,
  title: p.string,

  anchorRef: p.object,
  children: p.node,
}

FilterContainer.defaultProps = {
  icon: <FilterIcon />,
  title: 'Filter',
}
