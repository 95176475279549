/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useContext, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Redirect } from 'wouter'
import _ from 'lodash'
import DetailsPage from '../details/DetailsPage'
import ServiceEditDialog from './ServiceEditDialog'
import ServiceDeleteDialog from './ServiceDeleteDialog'
import { QuerySetFavoriteButton } from '../util/QuerySetFavoriteButton'
import Spinner from '../loading/components/Spinner'
import { GenericError, ObjectNotFound } from '../error-pages'
import ServiceOnCallList from './ServiceOnCallList'
import AppLink from '../util/AppLink'
import { ServiceAvatar } from '../util/avatars'
import ServiceMaintenanceModeDialog from './ServiceMaintenanceDialog'
import ServiceMaintenanceNotice from './ServiceMaintenanceNotice'
import Grid from '@mui/material/Grid'
import { ArrowBack as BackIcon } from '@mui/icons-material'
import '../util/services.css'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import { userContext } from '../main/App'

const query = gql`
  fragment ServiceTitleQuery on Service {
    id
    name
    description
  }

  query serviceDetailsQuery($serviceID: ID!) {
    service(id: $serviceID) {
      ...ServiceTitleQuery
      maintenanceExpiresAt
      ep: escalationPolicy {
        id
        name
      }
      heartbeatMonitors {
        id
        lastState
      }
      onCallUsers {
        userID
        userName
        stepNumber
      }
    }

    alerts(
      input: {
        filterByStatus: [StatusAcknowledged, StatusUnacknowledged]
        filterByServiceID: [$serviceID]
        first: 1
      }
    ) {
      nodes {
        id
        status
      }
    }
  }
`

const hbStatus = (h) => {
  if (!h || !h.length) return null
  if (h.every((m) => m.lastState === 'healthy')) return 'ok'
  if (h.some((m) => m.lastState === 'unhealthy')) return 'err'
  return 'warn'
}

const alertStatus = (a) => {
  if (!a) return null
  if (!a.length) return 'ok'
  if (a[0].status === 'StatusUnacknowledged') return 'err'
  return 'warn'
}

export default function ServiceDetails({ serviceID }) {
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showMaintMode, setShowMaintMode] = useState(false)
  const { data, loading, error } = useQuery(query, {
    variables: { serviceID },
    returnPartialData: true,
  })

  if (loading && !_.get(data, 'service.id')) return <Spinner />
  if (error) return <GenericError error={error.message} />

  if (!_.get(data, 'service.id')) {
    return showDelete ? <Redirect to='/services' /> : <ObjectNotFound />
  }

  /**
   *
   * @returns
   * Edit, Delete, Maintenance Mode buttons will appear when it is in control plane
   */
  function getServiceMenuOptions() {
    const plane = useContext(userContext)
    const isGAControlPlane = plane !== 'serviceplane'
    if (isGAControlPlane) {
      return (
        <div
          style={{
            display: 'flex',
            float: 'right',
            marginTop: '24px',
            position: 'relative',
            top: '10rem',
          }}
        >
          <React.Fragment>
            <DetailsPage
              primaryActions={[
                <Button
                  color='primary'
                  variant='contained'
                  key='maintence-mode'
                  onClick={() => setShowMaintMode(true)}
                  aria-label='Maintenance Mode'
                  style={{
                    fontSize: '16px',
                    width: '190px',
                    height: '46px',
                    position: 'relative',
                    right: '280px',
                    bottom: '50px',
                    borderRadius: '0px',
                    textTransform: 'capitalize',
                    backgroundColor: 'white',
                    color: '#042315',
                    border: '2px solid #707070',
                    fontFamily: 'IBM Plex Sans, sans-serif',
                    fontWeight: 500,
                    boxShadow: 'none',
                  }}
                >
                  Maintenance Mode
                </Button>,
              ]}
            />
          </React.Fragment>
          <Button className='but del' onClick={() => setShowDelete(true)}>
            Delete
          </Button>
          <Button className='but edi' onClick={() => setShowEdit(true)}>
            Edit
          </Button>
        </div>
      )
    }
  }

  return (
    <Grid container spacing={2}>
      <div className='ser-ds'>
        <div>
          <div>
            <IconButton
              className='ser-ib'
              size='large'
              style={{ paddingLeft: '0px' }}
            >
              <AppLink to='/services/'>
                <BackIcon className='ser-ic' />
              </AppLink>
            </IconButton>
            {getServiceMenuOptions()}
          </div>
        </div>
        <React.Fragment>
          <DetailsPage
            avatar={<ServiceAvatar />}
            title={data.service.name}
            notices={<ServiceMaintenanceNotice serviceID={serviceID} />}
            subheader={
              <React.Fragment>
                Response Policy:{' '}
                {_.get(data, 'service.ep') ? (
                  <AppLink to={`/escalation-policies/${data.service.ep.id}`}>
                    {data.service.ep.name}
                  </AppLink>
                ) : (
                  <Spinner text='Looking up policy...' />
                )}
              </React.Fragment>
            }
            details={data.service.description}
            pageContent={<ServiceOnCallList serviceID={serviceID} />}
            secondaryActions={[
              <QuerySetFavoriteButton
                key='secondary-action-favorite'
                id={serviceID}
                type='service'
              />,
            ]}
            links={[
              {
                label: 'Incidents',
                status: alertStatus(_.get(data, 'alerts.nodes')),
                url: 'alerts',
                subText: 'Manage incidents specific to this service',
              },
              {
                label: 'Heartbeat Monitors',
                url: 'heartbeat-monitors',
                status: hbStatus(_.get(data, 'service.heartbeatMonitors')),
                subText: 'Manage endpoints monitored for you',
              },
              {
                label: 'Integration Keys',
                url: 'integration-keys',
                subText: 'Manage keys used to create alerts',
              },
              {
                label: 'Labels',
                url: 'labels',
                subText: 'Group together services',
              },
              {
                label: 'Incident Metrics',
                url: 'alert-metrics',
                subText: 'Review incident activity',
              },
            ]}
          />
          {showEdit && (
            <ServiceEditDialog
              onClose={() => setShowEdit(false)}
              serviceID={serviceID}
            />
          )}
          {showDelete && (
            <ServiceDeleteDialog
              onClose={() => setShowDelete(false)}
              serviceID={serviceID}
            />
          )}
          {showMaintMode && (
            <ServiceMaintenanceModeDialog
              onClose={() => setShowMaintMode(false)}
              serviceID={serviceID}
              expiresAt={data.service.maintenanceExpiresAt}
            />
          )}
        </React.Fragment>
      </div>
    </Grid>
  )
}
