/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import { sortBy } from 'lodash'

export function formatNotificationRule(
  delayMinutes,
  { type, name, formattedValue },
) {
  const delayStr = delayMinutes
    ? `After ${delayMinutes} minute${delayMinutes === 1 ? '' : 's'}`
    : 'Immediately'

  return `${delayStr} notify me via ${type} at ${formattedValue} (${name})`
}

export function sortNotificationRules(nr) {
  return sortBy(nr, [
    'delayMinutes',
    'contactMethod.name',
    'contactMethod.type',
  ])
}

export function sortContactMethods(cm) {
  return sortBy(cm, ['name', 'type'])
}
