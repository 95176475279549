/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import { PropTypes as p } from 'prop-types'
import { FormContainer, FormField } from '../../forms'
import { Grid, TextField } from '@mui/material'
import { ScheduleSelect } from '../../selection'

export default function CalendarSubscribeForm(props) {
  return (
    <FormContainer
      disabled={props.loading}
      errors={props.errors}
      onChange={(value) => props.onChange(value)}
      optionalLabels
      removeFalseyIdxs
      value={props.value}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormField
            fullWidth
            component={TextField}
            name='name'
            placeholder='My iCloud Calendar'
            hint='This name is only used for Data Incidents, and will not appear in your calendar app.'
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            component={ScheduleSelect}
            disabled={props.scheduleReadOnly}
            fullWidth
            required
            label='Schedule'
            name='scheduleID'
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}

CalendarSubscribeForm.propTypes = {
  errors: p.array,
  loading: p.bool,
  onChange: p.func.isRequired,
  scheduleReadOnly: p.bool,
  value: p.shape({
    scheduleID: p.string,
    name: p.string,
    reminderMinutes: p.array,
  }).isRequired,
}
