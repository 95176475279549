/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import { gql, useMutation } from '@apollo/client'
import FormDialog from '../dialogs/FormDialog'
import { nonFieldErrors } from '../util/errutil'

const mutation = gql`
  mutation ($id: ID!) {
    deleteAll(input: [{ id: $id, type: notificationRule }])
  }
`
export default function UserNotificationRuleDeleteDialog(props: {
  ruleID: string
  onClose: () => void
}): JSX.Element {
  const { ruleID, ...rest } = props

  const [deleteNotification, { loading, error }] = useMutation(mutation, {
    onCompleted: props.onClose,
  })

  return (
    <FormDialog
      title='Are you sure?'
      confirm
      loading={loading}
      errors={nonFieldErrors(error)}
      subTitle='This will delete the notification rule.'
      onSubmit={() => deleteNotification({ variables: { id: ruleID } })}
      {...rest}
    />
  )
}
