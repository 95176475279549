/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import { useTheme } from '@mui/material'
import { yellow } from '@mui/material/colors'

type StatusColors = {
  ok: string
  warn: string
  err: string
}

function useStatusColors(): StatusColors {
  const theme = useTheme()
  return {
    ok: theme.palette.success.main,
    warn: yellow[600], // TODO if practical, use theme.palette.warning.main,
    err: theme.palette.error.main,
  }
}

export default useStatusColors
