/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useState, useCallback, useContext } from 'react'
import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import { ArrowBack as BackIcon } from '@mui/icons-material'

import DetailsPage from '../details/DetailsPage'
import ScheduleEditDialog from './ScheduleEditDialog'
import ScheduleDeleteDialog from './ScheduleDeleteDialog'
import ScheduleCalendarQuery from './calendar/ScheduleCalendarQuery'
import { QuerySetFavoriteButton } from '../util/QuerySetFavoriteButton'
import CalendarSubscribeButton from './calendar-subscribe/CalendarSubscribeButton'
import Spinner from '../loading/components/Spinner'
import { ObjectNotFound, GenericError } from '../error-pages'
import TempSchedDialog from './temp-sched/TempSchedDialog'
import TempSchedDeleteConfirmation from './temp-sched/TempSchedDeleteConfirmation'
import { ScheduleAvatar } from '../util/avatars'
import { useConfigValue } from '../util/RequireConfig'
import ScheduleCalendarOverrideDialog from './calendar/ScheduleCalendarOverrideDialog'
import { useIsWidthDown } from '../util/useWidth'
import { TempSchedValue } from './temp-sched/sharedUtils'
import { Redirect } from 'wouter'
import AppLink from '../util/AppLink'
import '../util/schedules.css'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import { userContext } from '../main/App'

const query = gql`
  fragment ScheduleTitleQuery on Schedule {
    id
    name
    description
  }
  query scheduleDetailsQuery($id: ID!) {
    schedule(id: $id) {
      ...ScheduleTitleQuery
      timeZone
    }
  }
`

interface OverrideDialog {
  variantOptions: string[]
  removeUserReadOnly: boolean
  defaultValue?: {
    addUserID?: string
    removeUserID?: string
    start: string
    end: string
  }
}

interface ScheduleCalendarContext {
  onNewTempSched: () => void
  onEditTempSched: (v: TempSchedValue) => void
  onDeleteTempSched: React.Dispatch<React.SetStateAction<TempSchedValue | null>>
  setOverrideDialog: React.Dispatch<React.SetStateAction<OverrideDialog | null>>
}

export const ScheduleCalendarContext =
  React.createContext<ScheduleCalendarContext>({
    onNewTempSched: () => {},
    onEditTempSched: () => {},
    onDeleteTempSched: () => {},
    setOverrideDialog: () => {},
  })

export type ScheduleDetailsProps = {
  scheduleID: string
}

export default function ScheduleDetails({
  scheduleID,
}: ScheduleDetailsProps): JSX.Element {
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [configTempSchedule, setConfigTempSchedule] =
    useState<Partial<TempSchedValue> | null>(null)
  const [deleteTempSchedule, setDeleteTempSchedule] =
    useState<TempSchedValue | null>(null)
  const isMobile = useIsWidthDown('md')

  const [slackEnabled] = useConfigValue('Slack.Enable')

  const onNewTempSched = useCallback(() => setConfigTempSchedule({}), [])
  const onEditTempSched = useCallback(
    (v: TempSchedValue) => setConfigTempSchedule(v),
    [],
  )
  const onDeleteTempSched = useCallback(setDeleteTempSchedule, [])
  const [overrideDialog, setOverrideDialog] = useState<OverrideDialog | null>(
    null,
  )

  const {
    data: _data,
    loading,
    error,
  } = useQuery(query, {
    variables: { id: scheduleID },
    returnPartialData: true,
  })

  const data = _.get(_data, 'schedule', null)

  if (loading && !data?.name) return <Spinner />
  if (error) return <GenericError error={error.message} />

  if (!data) {
    return showDelete ? <Redirect to='/schedules' /> : <ObjectNotFound />
  }

  /**
   *
   * @returns
   * Edit, Delete, Subscribe buttons will appear when it is in control plane
   */
  function getScheduleMenuOptions(): any {
    const plane = useContext(userContext)
    const isGAControlPlane: boolean = plane !== 'serviceplane'
    if (isGAControlPlane) {
      return (
        <div
          style={{
            float: 'right',
            marginTop: '24px',
            position: 'relative',
            top: '10rem',
          }}
        >
          <React.Fragment>
            <DetailsPage
              primaryActions={[
                <CalendarSubscribeButton
                  key='primary-action-subscribe'
                  scheduleID={scheduleID}
                />,
              ]}
            />
          </React.Fragment>
          <Button className='but del' onClick={() => setShowDelete(true)}>
            Delete
          </Button>
          <Button className='but edi' onClick={() => setShowEdit(true)}>
            Edit
          </Button>
        </div>
      )
    }
  }

  return (
    <Grid container spacing={2}>
      <div className='schedule-ds'>
        <div>
          <div>
            <IconButton
              className='schedule-ib'
              size='large'
              style={{ paddingLeft: '0px' }}
            >
              <AppLink to='/schedules/'>
                <BackIcon className='schedule-ic' />
              </AppLink>
            </IconButton>
            {getScheduleMenuOptions()}
          </div>
        </div>
        <React.Fragment>
          {showEdit && (
            <ScheduleEditDialog
              scheduleID={scheduleID}
              onClose={() => setShowEdit(false)}
            />
          )}
          {showDelete && (
            <ScheduleDeleteDialog
              scheduleID={scheduleID}
              onClose={() => setShowDelete(false)}
            />
          )}
          {configTempSchedule && (
            <TempSchedDialog
              value={configTempSchedule}
              onClose={() => setConfigTempSchedule(null)}
              scheduleID={scheduleID}
            />
          )}
          {deleteTempSchedule && (
            <TempSchedDeleteConfirmation
              value={deleteTempSchedule}
              onClose={() => setDeleteTempSchedule(null)}
              scheduleID={scheduleID}
            />
          )}
          <DetailsPage
            avatar={<ScheduleAvatar />}
            title={data.name}
            subheader={`Time Zone: ${data.timeZone || 'Loading...'}`}
            details={data.description}
            pageContent={
              <ScheduleCalendarContext.Provider
                value={{
                  onNewTempSched,
                  onEditTempSched,
                  onDeleteTempSched,
                  setOverrideDialog,
                }}
              >
                {!isMobile && <ScheduleCalendarQuery scheduleID={scheduleID} />}
                {overrideDialog && (
                  <ScheduleCalendarOverrideDialog
                    defaultValue={overrideDialog.defaultValue}
                    variantOptions={overrideDialog.variantOptions}
                    scheduleID={scheduleID}
                    onClose={() => setOverrideDialog(null)}
                    removeUserReadOnly={overrideDialog.removeUserReadOnly}
                  />
                )}
              </ScheduleCalendarContext.Provider>
            }
            secondaryActions={[
              <QuerySetFavoriteButton
                key='secondary-action-favorite'
                id={scheduleID}
                type='schedule'
              />,
            ]}
            links={[
              {
                label: 'Assignments',
                url: 'assignments',
                subText: 'Manage rules for rotations and users',
              },
              {
                label: 'Response Policies',
                url: 'escalation-policies',
                subText: 'Find response policies that link to this schedule',
              },
              {
                label: 'Overrides',
                url: 'overrides',
                subText: 'Add, remove, or replace a user temporarily',
              },
              {
                label: 'Shifts',
                url: 'shifts',
                subText: 'Review a list of past and future on-call shifts',
              },
            ].concat(
              slackEnabled
                ? [
                    {
                      // only slack is supported ATM, so hide the link if disabled

                      label: 'On-Call Notifications',
                      url: 'on-call-notifications',
                      subText: 'Set up notifications to know who is on-call',
                    },
                  ]
                : [],
            )}
          />
        </React.Fragment>
      </div>
    </Grid>
  )
}
