/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { cloneElement, forwardRef, ReactNode, useContext } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ChevronRight } from '@mui/icons-material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import Notices, { Notice } from './Notices'
import Markdown from '../util/Markdown'
import CardActions, { Action } from './CardActions'
import AppLink, { AppLinkProps } from '../util/AppLink'
import { useIsWidthDown } from '../util/useWidth'
import { userContext } from '../main/App'

interface DetailsPageProps {
  title: string

  // optional content
  avatar?: JSX.Element // placement for an icon or image
  subheader?: string | JSX.Element
  details?: string
  notices?: Array<Notice> | JSX.Element
  links?: Array<Link>
  pageContent?: JSX.Element
  primaryActions?: Array<Action | JSX.Element>
  secondaryActions?: Array<Action | JSX.Element>
}

type LinkStatus = 'ok' | 'warn' | 'err'
type Link = {
  url: string
  label: string
  subText?: string
  status?: LinkStatus
}

const useStyles = makeStyles({
  flexHeight: {
    flexGrow: 1,
  },
  fullHeight: {
    height: '100%', // align height of the first row of cards together
    boxShadow: 'none !important',
  },
  headerContent: {
    paddingTop: 0,
  },
  quickLinks: {
    paddingBottom: 8,
  },
  smPageBottom: {
    marginBottom: 64,
  },
})

const LIApplink = forwardRef<HTMLAnchorElement, AppLinkProps>(
  function LIApplink(props, ref): JSX.Element {
    return (
      <li>
        <AppLink ref={ref} {...props} />
      </li>
    )
  },
)

export default function DetailsPage(p: DetailsPageProps): JSX.Element {
  const classes = useStyles()
  const isMobile = useIsWidthDown('sm')

  const avatar = (): ReactNode => {
    if (!p.avatar) return null
    return cloneElement(p.avatar, {
      style: {
        width: 56,
        height: 56,
        position: 'absolute',
        top: 30,
        left: 60,
        backgroundColor: '#F2F1EE',
      },
    })
  }

  const links = (p.links || []).filter((l) => l)

  let headerDetailvalue: JSX.Element
  const plane = useContext(userContext)
  const isGAControlPlane: boolean = plane !== 'serviceplane'

  // return (
  if (isGAControlPlane) {
    headerDetailvalue = (
      <React.Fragment>
        <Grid container spacing={2}>
          {/* Notices */}
          {Array.isArray(p.notices) ? (
            <Notices notices={p.notices} />
          ) : (
            p.notices
          )}

          {/* Header card */}
          <Grid item xs={12} lg={!isMobile && p.links?.length ? 8 : 12}>
            <Card className={classes.fullHeight}>
              <Grid
                className={classes.fullHeight}
                item
                xs
                container
                direction='column'
              >
                <Grid item>
                  <CardHeader
                    title={p.title}
                    subheader={p.subheader}
                    avatar={avatar()}
                    titleTypographyProps={{
                      'data-cy': 'title',
                      variant: 'h5',
                      component: 'h2',
                      style: {
                        position: 'absolute',
                        top: 30,
                        left: 130,
                        marginRight: 40,
                        color: '#042315',
                        font: 'normal normal normal 20px/normal IBM Plex Sans, sans-serif',
                      },
                    }}
                    subheaderTypographyProps={{
                      'data-cy': 'subheader',
                      variant: 'body1',
                      style: {
                        position: 'absolute',
                        top: 60,
                        left: 130,
                        color: '#3D3C3C',
                        font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
                      },
                    }}
                    style={{ boxShadow: 'none !important' }}
                  />
                </Grid>

                {p.details && (
                  <Grid item>
                    <CardContent className={classes.headerContent}>
                      <Typography
                        component='div'
                        variant='subtitle1'
                        color='textSecondary'
                        data-cy='details'
                        style={{
                          position: 'absolute',
                          top: '110px',
                          left: '35px',
                          color: '#3D3C3C',
                          font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
                        }}
                      >
                        <Markdown value={p.details} />
                      </Typography>
                    </CardContent>
                  </Grid>
                )}

                <Grid className={classes.flexHeight} item />
                {(p.primaryActions?.length || p.secondaryActions?.length) && (
                  <Grid
                    item
                    style={{ position: 'absolute', right: '15px', top: '35px' }}
                  >
                    <CardActions
                      primaryActions={p.primaryActions}
                      secondaryActions={p.secondaryActions}
                    />
                  </Grid>
                )}
              </Grid>
            </Card>
          </Grid>

          {/* Quick Links */}
          {links.length > 0 && (
            <Grid
              item
              xs={12}
              lg={!isMobile && links.length ? 4 : 12}
              style={{
                marginLeft: '20px',
                marginTop: '120px',
                marginBottom: '20px',
                boxShadow: '0px 0px 10px #00000012',
                maxWidth: '100%',
                flexBasis: '100%',
              }}
            >
              <Card className={classes.fullHeight}>
                <CardHeader
                  title='Quick Links'
                  titleTypographyProps={{
                    variant: 'h5',
                    component: 'h2',
                  }}
                />
                <List
                  data-cy='route-links'
                  className={classes.quickLinks}
                  dense
                >
                  {links.map((li, idx) => (
                    <ListItem
                      key={idx}
                      component={LIApplink}
                      to={li.url}
                      button
                    >
                      <ListItemText
                        primary={li.label}
                        primaryTypographyProps={
                          !isMobile ? undefined : { variant: 'h5' }
                        }
                        secondary={li.subText}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          left: '160px',
                          top: '10px',
                        }}
                      >
                        <ChevronRight />
                      </div>
                    </ListItem>
                  ))}
                </List>
              </Card>
            </Grid>
          )}

          {/* Primary Page Content */}
          {p.pageContent && (
            <Grid
              className={isMobile ? classes.smPageBottom : undefined}
              item
              xs={12}
            >
              {p.pageContent}
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    )
  } else {
    headerDetailvalue = (
      <React.Fragment>
        <Grid container spacing={2}>
          {/* Notices */}
          {Array.isArray(p.notices) ? (
            <Notices notices={p.notices} />
          ) : (
            p.notices
          )}

          {/* Header card */}
          <Grid item xs={12} lg={!isMobile && p.links?.length ? 8 : 12}>
            <Card className={classes.fullHeight}>
              <Grid
                className={classes.fullHeight}
                item
                xs
                container
                direction='column'
              >
                <Grid item>
                  <CardHeader
                    title={p.title}
                    subheader={p.subheader}
                    avatar={avatar()}
                    titleTypographyProps={{
                      'data-cy': 'title',
                      variant: 'h5',
                      component: 'h2',
                      style: {
                        position: 'absolute',
                        top: 30,
                        left: 130,
                        marginRight: 40,
                        color: '#042315',
                        font: 'normal normal normal 20px/normal IBM Plex Sans, sans-serif',
                      },
                    }}
                    subheaderTypographyProps={{
                      'data-cy': 'subheader',
                      variant: 'body1',
                      style: {
                        position: 'absolute',
                        top: 60,
                        left: 130,
                        color: '#3D3C3C',
                        font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
                      },
                    }}
                    style={{ boxShadow: 'none !important' }}
                  />
                </Grid>

                {p.details && (
                  <Grid item>
                    <CardContent className={classes.headerContent}>
                      <Typography
                        component='div'
                        variant='subtitle1'
                        color='textSecondary'
                        data-cy='details'
                        style={{
                          position: 'absolute',
                          top: '110px',
                          left: '35px',
                          color: '#3D3C3C',
                          font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
                        }}
                      >
                        <Markdown value={p.details} />
                      </Typography>
                    </CardContent>
                  </Grid>
                )}

                <Grid className={classes.flexHeight} item />
                {(p.primaryActions?.length || p.secondaryActions?.length) && (
                  <Grid
                    item
                    style={{ position: 'absolute', right: '15px', top: '35px' }}
                  >
                    <CardActions
                      primaryActions={p.primaryActions}
                      secondaryActions={p.secondaryActions}
                    />
                  </Grid>
                )}
              </Grid>
            </Card>
          </Grid>

          {/* Quick Links */}
          {links.length > 0 && (
            <Grid
              item
              xs={12}
              lg={!isMobile && links.length ? 4 : 12}
              style={{
                marginLeft: '20px',
                marginTop: '120px',
                marginBottom: '20px',
                boxShadow: '0px 0px 10px #00000012',
                maxWidth: '100%',
                flexBasis: '100%',
              }}
            >
              <Card className={classes.fullHeight}>
                <CardHeader
                  title='Quick Links'
                  titleTypographyProps={{
                    variant: 'h5',
                    component: 'h2',
                  }}
                />
                <List
                  data-cy='route-links'
                  className={classes.quickLinks}
                  dense
                >
                  {links.map((li, idx) => (
                    <ListItem
                      key={idx}
                      component={LIApplink}
                      to={li.url}
                      button
                    >
                      <ListItemText
                        primary={li.label}
                        primaryTypographyProps={
                          !isMobile ? undefined : { variant: 'h5' }
                        }
                        secondary={li.subText}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          left: '160px',
                          top: '10px',
                        }}
                      >
                        <ChevronRight />
                      </div>
                    </ListItem>
                  ))}
                </List>
              </Card>
            </Grid>
          )}

          {/* Primary Page Content */}
          {p.pageContent && (
            <Grid
              className={isMobile ? classes.smPageBottom : undefined}
              item
              xs={12}
            >
              {p.pageContent}
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    )
  }

  return headerDetailvalue
}
