/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useContext, useState } from 'react'
import { useQuery, gql } from 'urql'
import _ from 'lodash'
import PolicyStepsQuery from './PolicyStepsQuery'
import PolicyDeleteDialog from './PolicyDeleteDialog'
import { QuerySetFavoriteButton } from '../util/QuerySetFavoriteButton'
import PolicyStepCreateDialog from './PolicyStepCreateDialog'
import DetailsPage from '../details/DetailsPage'
import PolicyEditDialog from './PolicyEditDialog'
import { useResetURLParams, useURLParam } from '../actions'
import { GenericError, ObjectNotFound } from '../error-pages'
import Spinner from '../loading/components/Spinner'
import { EPAvatar } from '../util/avatars'
import { Redirect } from 'wouter'
import Grid from '@mui/material/Grid'
import { ArrowBack as BackIcon } from '@mui/icons-material'
import AppLink from '../util/AppLink'
import '../util/escalationpolicies.css'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { userContext } from '../main/App'

const query = gql`
  query ($id: ID!) {
    escalationPolicy(id: $id) {
      id
      name
      description

      notices {
        type
        message
        details
      }
    }
  }
`

export default function PolicyDetails(props: {
  policyID: string
}): JSX.Element {
  const stepNumParam = 'createStep'
  const [createStep, setCreateStep] = useURLParam<boolean>(stepNumParam, false)
  const resetCreateStep = useResetURLParams(stepNumParam)

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)

  const [{ fetching, error, data: _data }] = useQuery({
    query,
    variables: {
      id: props.policyID,
    },
  })

  const data = _.get(_data, 'escalationPolicy', null)
  const plane = useContext(userContext)

  if (!data && fetching) return <Spinner />
  if (error) return <GenericError error={error.message} />

  if (!data) {
    return showDeleteDialog ? (
      <Redirect to='/escalation-policies' />
    ) : (
      <ObjectNotFound />
    )
  }

  /**
   *
   * @returns
   * Title for the policy details page
   */
  function renderEscalationHeaders(): any {
    return (
      <React.Fragment>
        <DetailsPage title={data.name} />
      </React.Fragment>
    )
  }

  /**
   *
   * @returns
   * Edit, Delete, Create Step buttons will appear when it is in control plane
   */
  function getEscalationMenuOptions(): any {
    const isGAControlPlane: boolean = plane !== 'serviceplane'
    if (isGAControlPlane) {
      return (
        <div
          style={{
            display: 'flex',
            float: 'right',
            marginTop: '24px',
            position: 'relative',
            top: '10rem',
          }}
        >
          <Button
            className='but cre'
            onClick={() => setCreateStep(true)}
            endIcon={<ControlPointIcon />}
          >
            Create Step
          </Button>
          <Button className='but del' onClick={() => setShowDeleteDialog(true)}>
            Delete
          </Button>
          <Button className='but edi' onClick={() => setShowEditDialog(true)}>
            Edit
          </Button>
        </div>
      )
    }
  }

  return (
    <Grid container spacing={2}>
      <div className='escpol-ds'>
        <div>
          <div>
            <IconButton
              className='escpol-ib'
              size='large'
              style={{ paddingLeft: '0px' }}
            >
              <AppLink to='/escalation-policies/'>
                <BackIcon className='escpol-ic' />
              </AppLink>
            </IconButton>
            <div className='escpol-header'>{renderEscalationHeaders()}</div>
            {getEscalationMenuOptions()}
          </div>
        </div>

        <React.Fragment>
          <DetailsPage
            notices={data.notices}
            avatar={<EPAvatar />}
            // title={data.name}
            details={data.description}
            pageContent={<PolicyStepsQuery escalationPolicyID={data.id} />}
            secondaryActions={[
              <QuerySetFavoriteButton
                key='secondary-action-favorite'
                id={data.id}
                type='escalationPolicy'
              />,
            ]}
            links={[
              {
                label: 'Services',
                url: 'services',
                subText: 'Find services that link to this policy',
              },
            ]}
          />
          {createStep && (
            <PolicyStepCreateDialog
              escalationPolicyID={data.id}
              onClose={resetCreateStep}
            />
          )}
          {showEditDialog && (
            <PolicyEditDialog
              escalationPolicyID={data.id}
              onClose={() => setShowEditDialog(false)}
            />
          )}
          {showDeleteDialog && (
            <PolicyDeleteDialog
              escalationPolicyID={data.id}
              onClose={() => setShowDeleteDialog(false)}
            />
          )}
        </React.Fragment>
      </div>
    </Grid>
  )
}
