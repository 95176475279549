/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { ReactElement } from 'react'
import Fade from '@mui/material/Fade'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

export const FadeTransition = React.forwardRef(
  ({ children, ...props }: TransitionProps, ref) => (
    <Fade {...props} ref={ref}>
      {children as ReactElement}
    </Fade>
  ),
)
FadeTransition.displayName = 'FadeTransition'

export const SlideTransition = React.forwardRef(
  ({ children, ...props }: TransitionProps, ref) => (
    <Slide {...props} direction='left' ref={ref}>
      {children as ReactElement}
    </Slide>
  ),
)
SlideTransition.displayName = 'SlideTransition'
