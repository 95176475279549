/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import Typography from '@mui/material/Typography'
import {
  SentimentDissatisfied,
  SentimentVeryDissatisfied,
} from '@mui/icons-material'
import { useTheme } from '@mui/material'

interface ErrorsProps {
  error?: string
  type?: string
}

export function PageNotFound(): JSX.Element {
  const theme = useTheme()
  return (
    <div style={{ textAlign: 'center', color: theme.palette.text.primary }}>
      <SentimentDissatisfied style={{ height: '33vh', width: '33vw' }} />
      <Typography variant='h5'>
        Sorry, the page you were trying to reach could not be found.
      </Typography>
    </div>
  )
}

export function ObjectNotFound(props: ErrorsProps): JSX.Element {
  const theme = useTheme()
  return (
    <div style={{ textAlign: 'center', color: theme.palette.text.primary }}>
      <SentimentDissatisfied style={{ height: '33vh', width: '33vw' }} />
      <Typography variant='h5'>
        Sorry, the {props.type || 'thing'} you were looking for could not be
        found.
      </Typography>
      <Typography variant='caption'>
        Someone may have deleted it, or it never existed.
      </Typography>
    </div>
  )
}

export function GenericError(props: ErrorsProps): JSX.Element {
  const theme = useTheme()
  let errorText
  if (props.error) {
    errorText = <Typography variant='caption'>{props.error}</Typography>
  }
  return (
    <div style={{ textAlign: 'center', color: theme.palette.text.primary }}>
      <SentimentVeryDissatisfied style={{ height: '33vh', width: '33vw' }} />
      <Typography variant='h5'>Sorry, an error occurred.</Typography>
      {errorText}
    </div>
  )
}
