/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

interface LoadingButtonProps extends ButtonProps {
  attemptCount?: number
  buttonText?: string
  loading?: boolean
  noSubmit?: boolean
  style?: React.CSSProperties
}

const LoadingButton = (props: LoadingButtonProps): JSX.Element => {
  const {
    attemptCount,
    buttonText,
    color,
    disabled,
    loading,
    noSubmit,
    onClick,
    style,
    ...rest
  } = props

  return (
    <div style={{ position: 'relative', ...style }}>
      <Button
        {...rest}
        data-cy='loading-button'
        variant='contained'
        color={color || 'primary'}
        style={{
          backgroundColor: '#4CDD84',
          color: '#042315',
          textTransform: 'capitalize',
          borderRadius: 0,
          fontSize: '16px',
          fontFamily: 'IBM Plex Sans, sans-serif',
          fontWeight: '500',
          boxShadow: 'none',
        }}
        onClick={onClick}
        disabled={loading || disabled}
        type={noSubmit ? 'button' : 'submit'}
      >
        {!attemptCount ? buttonText || 'Confirm' : 'Retry'}
      </Button>
      {loading && (
        <CircularProgress
          color={color || 'primary'}
          size={24}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      )}
    </div>
  )
}

export default LoadingButton
