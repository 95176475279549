/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import { useQuery, gql } from 'urql'
import FlatList from '../lists/FlatList'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Spinner from '../loading/components/Spinner'
import { GenericError } from '../error-pages'
import { ArrowBack as BackIcon } from '@mui/icons-material'
import AppLink from '../util/AppLink'
import '../util/schedules.css'
import IconButton from '@mui/material/IconButton'

const query = gql`
  query ($id: ID!) {
    schedule(id: $id) {
      id
      assignedTo {
        id
        type
        name
      }
    }
  }
`

export default function ScheduleAssignedToList(props: {
  scheduleID: string
}): JSX.Element {
  const [{ data, fetching, error }] = useQuery({
    query,
    variables: { id: props.scheduleID },
  })

  if (error) {
    return <GenericError error={error.message} />
  }

  if (fetching && !data) {
    return <Spinner />
  }

  return (
    <Card sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <IconButton
            className='schedule-ib'
            size='large'
            style={{ paddingLeft: '0px' }}
          >
            <AppLink to={`/schedules/${props.scheduleID}`}>
              <BackIcon className='schedule-ic' />
            </AppLink>
          </IconButton>
          <FlatList
            items={data.schedule.assignedTo.map(
              (t: { name: string; id: string }) => ({
                title: t.name,
                url: `/escalation-policies/${t.id}`,
              }),
            )}
            emptyMessage='This schedule is not assigned to any response policies.'
          />
        </Grid>
      </Grid>
    </Card>
  )
}
