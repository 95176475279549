/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useContext, useState } from 'react'
import { gql, useQuery } from 'urql'
import _ from 'lodash'
import { Redirect } from 'wouter'
import Grid from '@mui/material/Grid'
import { ArrowBack as BackIcon } from '@mui/icons-material'

import { handoffSummary } from './util'
import DetailsPage from '../details/DetailsPage'
import RotationEditDialog from './RotationEditDialog'
import RotationDeleteDialog from './RotationDeleteDialog'
import RotationUserList from './RotationUserList'
import RotationAddUserDialog from './RotationAddUserDialog'
import { QuerySetFavoriteButton } from '../util/QuerySetFavoriteButton'
import Spinner from '../loading/components/Spinner'
import { ObjectNotFound, GenericError } from '../error-pages'
import { RotationAvatar } from '../util/avatars'
import AppLink from '../util/AppLink'
import '../util/rotations.css'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { userContext } from '../main/App'

const query = gql`
  fragment RotationTitleQuery on Rotation {
    id
    name
    description
  }

  query rotationDetails($id: ID!) {
    rotation(id: $id) {
      ...RotationTitleQuery

      activeUserIndex
      userIDs
      type
      shiftLength
      timeZone
      start
    }
  }
`

export default function RotationDetails(props: {
  rotationID: string
}): JSX.Element {
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showAddUser, setShowAddUser] = useState(false)

  const [{ data: _data, fetching, error }] = useQuery({
    query,
    variables: { id: props.rotationID },
  })

  const data = _.get(_data, 'rotation', null)

  if (fetching && !data?.name) return <Spinner />
  if (error) return <GenericError error={error.message} />

  if (!data)
    return showDelete ? (
      <Redirect to='/rotations' />
    ) : (
      <ObjectNotFound type='rotation' />
    )

  /**
   *
   * @returns
   * Edit, Delete, Add User buttons will appear when it is in control plane
   */
  function getRotationMenuOptions(): any {
    const plane = useContext(userContext)
    const isGAControlPlane: boolean = plane !== 'serviceplane'
    if (isGAControlPlane) {
      return (
        <div
          style={{
            display: 'flex',
            float: 'right',
            marginTop: '24px',
            position: 'relative',
            top: '10rem',
          }}
        >
          <Button
            className='but add'
            onClick={() => setShowAddUser(true)}
            endIcon={<ControlPointIcon />}
          >
            Add User
          </Button>
          <Button className='but del' onClick={() => setShowDelete(true)}>
            Delete
          </Button>
          <Button className='but edi' onClick={() => setShowEdit(true)}>
            Edit
          </Button>
        </div>
      )
    }
  }

  return (
    <Grid container spacing={2}>
      <div className='ro-ds'>
        <div>
          <div>
            <IconButton
              className='ro-ib'
              size='large'
              style={{ paddingLeft: '0px' }}
            >
              <AppLink to='/rotations/'>
                <BackIcon className='ro-ic' />
              </AppLink>
            </IconButton>
            {getRotationMenuOptions()}
          </div>
        </div>
        <React.Fragment>
          {showAddUser && (
            <RotationAddUserDialog
              rotationID={props.rotationID}
              userIDs={data.userIDs}
              onClose={() => setShowAddUser(false)}
            />
          )}
          {showEdit && (
            <RotationEditDialog
              rotationID={props.rotationID}
              onClose={() => setShowEdit(false)}
            />
          )}
          {showDelete && (
            <RotationDeleteDialog
              rotationID={props.rotationID}
              onClose={() => setShowDelete(false)}
            />
          )}
          <DetailsPage
            avatar={<RotationAvatar />}
            title={data.name}
            subheader={handoffSummary(data)}
            details={data.description}
            pageContent={<RotationUserList rotationID={props.rotationID} />}
            secondaryActions={[
              <QuerySetFavoriteButton
                key='secondary-action-favorite'
                id={props.rotationID}
                type='rotation'
              />,
            ]}
          />
        </React.Fragment>
      </div>
    </Grid>
  )
}
