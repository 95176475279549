/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import p from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'

export default function OtherActionsMobile({ isOpen, onClose, actions }) {
  return (
    <SwipeableDrawer
      anchor='bottom'
      disableDiscovery
      disableSwipeToOpen
      open={isOpen}
      onOpen={() => null}
      onClose={onClose}
    >
      <List data-cy='mobile-actions'>
        {actions.map((o, idx) => (
          <ListItem
            key={idx}
            button
            onClick={() => {
              onClose()
              o.onClick()
            }}
          >
            <ListItemText primary={o.label} />
          </ListItem>
        ))}
      </List>
    </SwipeableDrawer>
  )
}

OtherActionsMobile.propTypes = {
  onClose: p.func,
  isOpen: p.bool,
  actions: p.arrayOf(
    p.shape({
      label: p.string.isRequired,
      onClick: p.func.isRequired,
    }),
  ),
}
