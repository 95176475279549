/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import { gql } from 'urql'
import SimpleListPage from '../lists/SimpleListPage'
import ScheduleCreateDialog from './ScheduleCreateDialog'
import '../util/schedules.css'

const query = gql`
  query schedulesQuery($input: ScheduleSearchOptions) {
    data: schedules(input: $input) {
      nodes {
        id
        name
        description
        isFavorite
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export default function ScheduleList(): JSX.Element {
  return (
    <div className='scheduleTbt'>
      <h1 className='scheduleHeader'> Schedules </h1>
      <div>
        <SimpleListPage
          query={query}
          variables={{ input: { favoritesFirst: true } }}
          mapDataNode={(n) => ({
            title: n.name,
            subText: n.description,
            url: n.id,
            isFavorite: n.isFavorite,
          })}
          createForm={<ScheduleCreateDialog />}
          createLabel='Schedule'
        />
      </div>
    </div>
  )
}
