/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import integrationKeys from './sections/IntegrationKeys.md'
import webhooks from './sections/Webhooks.md'
import Markdown from '../util/Markdown'
import { useConfigValue } from '../util/RequireConfig'
import { pathPrefix } from '../env'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  mBottom: {
    marginBottom: '3rem',
  },
})

export default function Documentation(): JSX.Element {
  const [publicURL, webhookEnabled] = useConfigValue(
    'General.PublicURL',
    'Webhook.Enable',
  )
  const classes = useStyles()

  // NOTE list markdown documents here
  let markdownDocs = [integrationKeys]
  if (webhookEnabled) {
    markdownDocs.push(webhooks)
  }

  markdownDocs = markdownDocs.map((md) =>
    md.replaceAll(
      'https://<example.goalert.me>',
      publicURL || `${window.location.origin}${pathPrefix}`,
    ),
  )

  return (
    <React.Fragment>
      {markdownDocs.map((doc, i) => (
        <Card key={i} className={classes.mBottom}>
          <CardContent>
            <Typography variant='body1' component='div'>
              <Markdown value={doc} />
            </Typography>
          </CardContent>
        </Card>
      ))}
    </React.Fragment>
  )
}
