/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function debug(message?: any, ...optionalParams: any[]): void {
  if (!window.console) return // some browsers don't define console if the devtools are closed
  if (console.debug) console.debug(message, ...optionalParams)
  // not all browsers have `.debug` defined
  else console.log(message, ...optionalParams)
}

export function warn(message?: any, ...optionalParams: any[]): void {
  if (!window.console) return // some browsers don't define console if the devtools are closed
  if (console.warn) console.warn(message, ...optionalParams)
  // not all browsers have `.warn` defined
  else console.log(message, ...optionalParams)
}
