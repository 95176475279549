/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { ReactElement, useState, ReactNode } from 'react'
import p from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles'
import { ArrowBack as BackIcon } from '@mui/icons-material'
import Countdown from 'react-countdown'
import { gql, useMutation } from '@apollo/client'
import { DateTime } from 'luxon'
import _ from 'lodash'
import {
  RotationLink,
  ScheduleLink,
  ServiceLink,
  SlackChannelLink,
  UserLink,
} from '../../links'
import { styles as globalStyles } from '../../styles/materialStyles'
import Markdown from '../../util/Markdown'
import AlertDetailLogs from '../AlertDetailLogs'
import AppLink from '../../util/AppLink'
import {
  Alert,
  Target,
  EscalationPolicyStep,
  AlertStatus,
} from '../../../schema'
import '../../util/alerts.css'
import { formatTimeSince } from '../../util/timeFormat'
import IconButton from '@mui/material/IconButton'
import { useIsWidthDown } from '../../util/useWidth'

interface AlertDetailsProps {
  data: Alert
}

interface EscalationPolicyInfo {
  repeatCount?: number
  repeat?: number
  numSteps?: number
  steps?: EscalationPolicyStep[]
  status: AlertStatus
  currentLevel?: number
  lastEscalation?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  card: globalStyles(theme).card,
  cardContainer: globalStyles(theme).cardContainer,
  cardFull: globalStyles(theme).cardFull,
  tableCardContent: globalStyles(theme).tableCardContent,
  epHeader: {
    paddingBottom: 8,
  },
}))

const localStorage = window.localStorage
const exactTimesKey = 'show_exact_times'

const updateStatusMutation = gql`
  mutation UpdateAlertsMutation($input: UpdateAlertsInput!) {
    updateAlerts(input: $input) {
      id
    }
  }
`

export default function AlertDetails(props: AlertDetailsProps): JSX.Element {
  const fullScreen = useIsWidthDown('lg')
  const classes = useStyles()

  const [ack] = useMutation(updateStatusMutation, {
    variables: {
      input: {
        alertIDs: [props.data.id],
        newStatus: 'StatusAcknowledged',
      },
    },
  })
  const [close] = useMutation(updateStatusMutation, {
    variables: {
      input: {
        alertIDs: [props.data.id],
        newStatus: 'StatusClosed',
      },
    },
  })
  const [escalate] = useMutation(
    gql`
      mutation EscalateAlertMutation($input: [Int!]) {
        escalateAlerts(input: $input) {
          id
        }
      }
    `,
    {
      variables: {
        input: [props.data.id],
      },
    },
  )

  // localstorage stores true/false as a string; convert to a bool
  // default to true if localstorage is not set
  let _showExactTimes = localStorage.getItem(exactTimesKey) || false
  if (typeof _showExactTimes !== 'boolean') {
    _showExactTimes = _showExactTimes === 'true'
  }

  const [fullDescription, setFullDescription] = useState(false)
  const [showExactTimes, setShowExactTimes] = useState(_showExactTimes)

  /*
   * Update state and local storage with new boolean value
   * telling whether or not the show exact times toggle is active
   */
  function handleToggleExactTimes(): void {
    const newVal = !showExactTimes
    setShowExactTimes(newVal)
    localStorage.setItem(exactTimesKey, newVal.toString())
  }

  function getCardClassName(): any {
    return fullScreen ? classes.cardFull : classes.card
  }

  function renderTargets(targets: Target[], stepID: string): ReactElement[] {
    return _.sortBy(targets, 'name').map((target, i) => {
      const separator = i === 0 ? '' : ', '

      let link
      const t = target.type
      if (t === 'rotation') link = RotationLink(target)
      else if (t === 'schedule') link = ScheduleLink(target)
      else if (t === 'slackChannel') link = SlackChannelLink(target)
      else if (t === 'user') link = UserLink(target)
      else link = target.name

      return (
        <span key={stepID + target.id}>
          {separator}
          {link}
        </span>
      )
    })
  }

  /*
   * Returns properties from the escalation policy
   * for easier use in functions.
   */
  function epsHelper(): EscalationPolicyInfo {
    const ep = props.data?.service?.escalationPolicy
    const alert = props.data
    const state = props.data.state

    return {
      repeatCount: state?.repeatCount,
      repeat: ep?.repeat,
      numSteps: ep?.steps.length,
      steps: ep?.steps,
      status: alert.status,
      currentLevel: state?.stepNumber,
      lastEscalation: state?.lastEscalation,
    }
  }

  function canAutoEscalate(): boolean {
    const { currentLevel, status, steps, repeat, repeatCount } = epsHelper()

    if (status !== 'StatusUnacknowledged') {
      return false
    }

    if (
      currentLevel === (steps?.length ?? 0) - 1 &&
      (repeatCount ?? 0) >= (repeat ?? 0)
    ) {
      return false
    }

    return true
  }

  function getNextEscalation(): JSX.Element | string {
    const { currentLevel, lastEscalation, steps } = epsHelper()
    const prevEscalation = new Date(lastEscalation ?? '')

    if (canAutoEscalate()) {
      return (
        <Countdown
          date={
            new Date(
              prevEscalation.getTime() +
                (steps ? steps[currentLevel ?? 0].delayMinutes : 0) * 60000,
            )
          }
          overtime
          renderer={(props) => {
            const { hours, minutes, seconds, completed } = props

            if (completed) return 'Escalating...'

            const hourTxt = hours
              ? `${hours} hour${hours === 1 ? '' : 's'} `
              : ''
            const minTxt = minutes
              ? `${minutes} minute${minutes === 1 ? '' : 's'} `
              : ''
            const secTxt = `${seconds} second${seconds === 1 ? '' : 's'}`

            return hourTxt + minTxt + secTxt
          }}
        />
      )
    }

    return 'None'
  }

  function renderEscalationPolicySteps(): JSX.Element[] | JSX.Element {
    const { steps, status, currentLevel } = epsHelper()

    if (!steps?.length) {
      return (
        <TableRow>
          <TableCell>No steps</TableCell>
          <TableCell>&mdash;</TableCell>
          <TableCell>&mdash;</TableCell>
        </TableRow>
      )
    }

    return steps.map((step, index) => {
      const mod = index % 2 === 0 ? '#F8F8F8' : '#FFFFFF'
      const { id, targets } = step

      const rotations = targets.filter((t) => t.type === 'rotation')
      const schedules = targets.filter((t) => t.type === 'schedule')
      const slackChannels = targets.filter((t) => t.type === 'slackChannel')
      const users = targets.filter((t) => t.type === 'user')
      const selected =
        status !== 'StatusClosed' &&
        (currentLevel ?? 0) % steps.length === index

      return (
        <TableRow
          style={{ width: '100%', backgroundColor: mod }}
          key={index}
          selected={selected}
        >
          <TableCell style={{ width: '10%' }}>Step #{index + 1}</TableCell>
          <TableCell style={{ width: '80%' }}>
            {!targets.length && <Typography>&mdash;</Typography>}
            {rotations.length > 0 && (
              <div>Rotations: {renderTargets(rotations, id)}</div>
            )}
            {schedules.length > 0 && (
              <div>Schedules: {renderTargets(schedules, id)}</div>
            )}
            {slackChannels.length > 0 && (
              <div>Slack Channels: {renderTargets(slackChannels, id)}</div>
            )}
            {users.length > 0 && <div>Users: {renderTargets(users, id)}</div>}
          </TableCell>
        </TableRow>
      )
    })
  }

  function renderAlertDetails(): ReactNode {
    const alert = props.data
    let details = (alert.details || '').trim()
    if (!details) return null

    if (!fullDescription && details.length > 1000) {
      details = details.slice(0, 1000).trim() + ' ...'
    }

    let expandTextAction = null
    if (details.length > 1000) {
      let text = 'Show Less'

      if (!fullDescription) {
        text = 'Show More'
      }

      expandTextAction = (
        <Typography
          color='textSecondary'
          onClick={() => setFullDescription(!fullDescription)}
          style={{
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'center',
            textAlign: 'center',
            paddingTop: '1em',
          }}
        >
          {text}
        </Typography>
      )
    }

    return (
      <Grid item data-cy='alert-details'>
        <div className='dls'>
          {/* <Typography component='h3' variant='h5'> */}
          <div className='ds-txt'>Details</div>
          {/* </Typography> */}
          <Typography variant='body1' component='div' className='para'>
            <Markdown value={details + '\n'} />
          </Typography>
          {expandTextAction}
        </div>
      </Grid>
    )
  }

  function getStatusFilter(s: any): any {
    if (s === 'StatusAcknowledged') {
      return (
        <div style={{ display: 'flex' }}>
          <div className='dot ack' />
          <div className='ml d-txt'>Acknowledged</div>
        </div>
      )
    }
    if (s === 'StatusUnacknowledged') {
      return (
        <div style={{ display: 'flex' }}>
          <div className='dot unack' />
          <div className='ml d-txt'>Unacknowledged</div>
        </div>
      )
    }
    if (s === 'StatusClosed') {
      return (
        <div style={{ display: 'flex' }}>
          <div className='dot cls' />
          <div className='ml d-txt'>Closed</div>
        </div>
      )
    }
    return ''
  }

  /*
   * Options to show for alert details menu
   */
  function getMenuOptions(): any {
    const { status } = props.data
    let options = false

    if (status === 'StatusClosed') return (options = false)
    if (status === 'StatusUnacknowledged') {
      options = true
    }

    // const isMaintMode = Boolean(props.data?.service?.maintenanceExpiresAt)

    // only remaining status is acknowledged, show remaining buttons
    return (
      <div
        style={{
          display: 'flex',
          float: 'right',
          marginTop: '24px',
          justifyContent: 'right',
          width: '42%',
        }}
      >
        <Button className='bt rjt' onClick={() => close()}>
          Close
        </Button>
        <Button className='bt rjt' onClick={() => escalate()}>
          Response
        </Button>
        {options === true ? (
          <Button
            className='bt act'
            style={{ color: 'black' }}
            onClick={() => ack()}
          >
            Acknowledge
          </Button>
        ) : (
          ''
        )}
      </div>
    )
  }

  const { data: alert } = props
  return (
    <Grid container spacing={2}>
      <div className='al-ds'>
        <div>
          <div>
            <IconButton
              size='large'
              className='backIc'
              style={{ paddingLeft: '0px', backgroundColor: 'transparent' }}
            >
              <AppLink to='/alerts?allServices=1'>
                <BackIcon className='ic' />
              </AppLink>
            </IconButton>
            <span
              className='summary'
              style={{ paddingLeft: '10px', paddingTop: '5px' }}
            >
              {' '}
              Incidents{' '}
            </span>
            <div className='tt-no'>Ticket Number: {alert.alertID}</div>
            <div className='flx'>
              <div className='summary'>{alert.summary}</div>
              <div className='rt'>
                <div className='time'>
                  {alert.createdAt !== '' && alert.createdAt !== undefined
                    ? formatTimeSince(alert.createdAt)
                    : ''}
                </div>
                <div>{getStatusFilter(alert.status)} </div>
              </div>
            </div>
            <div className='type'>
              <span className='ty-txt'>{ServiceLink(alert.service)}</span>
            </div>
            <div className='cd'>
              <div className='details'>{renderAlertDetails()}</div>
            </div>
          </div>
          {getMenuOptions()}
          <div style={{ marginTop: '80px' }}>
            <div
              style={{ color: '#042315', fontSize: '16px', fontWeight: '500' }}
            >
              <AppLink
                to={`/escalation-policies/${alert.service?.escalationPolicy?.id}`}
                style={{ textDecoration: 'none' }}
              >
                Response Policy
              </AppLink>
            </div>
            {alert?.state?.lastEscalation && (
              <React.Fragment>
                <Typography
                  color='textSecondary'
                  variant='caption'
                  style={{
                    font: '14px/18px IBM Plex Sans, sans-serif',
                  }}
                >
                  Last Response:{' '}
                  {DateTime.fromISO(alert.state.lastEscalation).toFormat(
                    'dd-MMM-yyyy hh:mm a',
                  )}
                </Typography>
                <br />
                <Typography
                  color='textSecondary'
                  variant='caption'
                  style={{
                    font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
                  }}
                >
                  Next Response: {getNextEscalation()}
                </Typography>
              </React.Fragment>
            )}
            <div style={{ marginTop: '10px' }}>
              <div className='ep-cd'>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#042315',
                          }}
                        >
                          Step
                        </div>
                      </TableCell>
                      <TableCell style={{ width: '80%' }}>
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#042315',
                          }}
                        >
                          Incidents
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderEscalationPolicySteps()}</TableBody>
                </Table>
                <div style={{ padding: '16px 20px' }}>
                  <Typography
                    color='textSecondary'
                    variant='caption'
                    style={{
                      font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
                    }}
                  >
                    Visit this response policy for more information.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '30px' }}>
            <Card className={getCardClassName()}>
              <div style={{ display: 'flex' }}>
                <CardContent style={{ flex: 1, paddingBottom: 0 }}>
                  <div
                    style={{
                      color: '#042315',
                      fontSize: '16px',
                      fontWeight: '500',
                    }}
                  >
                    Event Log
                  </div>
                </CardContent>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showExactTimes}
                      onChange={handleToggleExactTimes}
                    />
                  }
                  label='Full Timestamps'
                  style={{ padding: '0.5em 0.5em 0 0' }}
                />
              </div>
              <CardContent
                className={classes.tableCardContent}
                style={{ paddingBottom: 0 }}
              >
                <AlertDetailLogs
                  alertID={props.data.alertID}
                  showExactTimes={showExactTimes}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </Grid>
  )
}

AlertDetails.propTypes = {
  error: p.shape({ message: p.string }),
}
