/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useState, MouseEvent } from 'react'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import Hidden from '@mui/material/Hidden'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles'
import { styles as globalStyles } from '../../styles/materialStyles'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import classnames from 'classnames'
import { useURLParam, useResetURLParams } from '../../actions'
import { useIsWidthUp } from '../../util/useWidth'
import { gql } from '@apollo/client'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import '../../util/alerts.css'

export const alertsListQuery = gql`
  query alertsList($input: AlertSearchOptions) {
    alerts(input: $input) {
      nodes {
        id
        alertID
        status
        summary
        details
        createdAt
        service {
          id
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
const useStyles = makeStyles((theme: Theme) => ({
  filterActions: globalStyles(theme).filterActions,
  drawer: {
    width: 'fit-content', // width placed on mobile drawer
  },
  grid: {
    margin: '0.5em', // margin in grid container
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center', // aligns text with toggles
  },
  formControl: {
    width: '100%', // date pickers full width
  },
  popover: {
    width: '17em', // width placed on desktop popover
  },
}))

interface AlertsListFilterProps {
  serviceID: string
}

function AlertsListFilter(props: AlertsListFilterProps): JSX.Element {
  const classes = useStyles()
  const [show, setShow] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const [filter, setFilter] = useURLParam<string>('filter', 'all')
  const [allServices, setAllServices] = useURLParam<boolean>(
    'allServices',
    true,
  )
  const [showAsFullTime, setShowAsFullTime] = useURLParam<boolean>(
    'fullTime',
    false,
  )
  const resetAll = useResetURLParams('filter', 'allServices', 'fullTime') // don't reset search param

  // grabs class for width depending on breakpoints (md or higher uses popover width)
  const widthClass = useIsWidthUp('md') ? classes.popover : classes.drawer
  const gridClasses = classnames(classes.grid, widthClass)

  function handleOpenFilters(event: MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event.currentTarget)
    setShow(true)
  }

  function handleCloseFilters(): void {
    setShow(false)
  }

  function renderFilters(): JSX.Element {
    let favoritesFilter = null
    if (!props.serviceID) {
      favoritesFilter = (
        <FormControlLabel
          control={
            <Switch
              aria-label='Include All Services Toggle'
              data-cy='toggle-favorites'
              value={allServices}
              checked={allServices}
              onChange={() => setAllServices(!allServices)}
            />
          }
          label='Include All Services'
        />
      )
    }

    const content = (
      <Grid container spacing={2} className={gridClasses}>
        <Grid item xs={12} className={classes.gridItem}>
          <FormControl>
            {favoritesFilter}
            <FormControlLabel
              control={
                <Switch
                  aria-label='Show full timestamps toggle'
                  name='toggle-full-time'
                  checked={showAsFullTime}
                  onChange={() => setShowAsFullTime(!showAsFullTime)}
                />
              }
              label='Show full timestamps'
            />
            <RadioGroup
              aria-label='Alert Status Filters'
              name='status-filters'
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <FormControlLabel
                value='active'
                control={<Radio />}
                label='Active'
              />
              <FormControlLabel
                value='unacknowledged'
                control={<Radio />}
                label='Unacknowledged'
              />
              <FormControlLabel
                value='acknowledged'
                control={<Radio />}
                label='Acknowledged'
              />
              <FormControlLabel
                value='closed'
                control={<Radio />}
                label='Closed'
              />
              <FormControlLabel value='all' control={<Radio />} label='All' />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.filterActions}>
          <Button className='bt reset' onClick={resetAll}>
            Reset
          </Button>
          <Button className='bt done' onClick={handleCloseFilters}>
            Done
          </Button>
        </Grid>
      </Grid>
    )

    // renders a popover on desktop, and a swipeable drawer on mobile devices
    return (
      <React.Fragment>
        <Hidden mdDown>
          <Popover
            anchorEl={anchorEl}
            open={!!anchorEl && show}
            onClose={handleCloseFilters}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {content}
          </Popover>
        </Hidden>
        <Hidden mdUp>
          <SwipeableDrawer
            anchor='top'
            disableDiscovery
            disableSwipeToOpen
            open={show}
            onClose={handleCloseFilters}
            onOpen={handleOpenFilters}
          >
            {content}
          </SwipeableDrawer>
        </Hidden>
      </React.Fragment>
    )
  }

  /*
   * Finds the parent toolbar DOM node and appends the options
   * element to that node (after all the toolbar's children
   * are done being rendered)
   */

  return (
    <React.Fragment>
      <Button
        variant='contained'
        aria-label='Filter Alerts'
        style={{
          textTransform: 'none',
          color: '#525252',
          backgroundColor: '#f4f4f4',
          fontSize: '14px',
          boxShadow: 'none',
          padding: '7px 14px 7px 14px',
          borderRadius: '0px',
        }}
        onClick={handleOpenFilters}
        size='large'
        endIcon={<KeyboardArrowDownIcon />}
      >
        Search by
      </Button>

      {renderFilters()}
    </React.Fragment>
  )
}

export default AlertsListFilter
