/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useContext, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import p from 'prop-types'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import FlatList from '../lists/FlatList'
import IconButton from '@mui/material/IconButton'
import { Trash } from '../icons'
import IntegrationKeyCreateDialog from './IntegrationKeyCreateDialog'
import IntegrationKeyDeleteDialog from './IntegrationKeyDeleteDialog'
import RequireConfig from '../util/RequireConfig'
import CopyText from '../util/CopyText'
import AppLink from '../util/AppLink'

import makeStyles from '@mui/styles/makeStyles'
import Spinner from '../loading/components/Spinner'
import { GenericError } from '../error-pages'
import Button from '@mui/material/Button'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { ArrowBack as BackIcon } from '@mui/icons-material'
import '../util/services.css'
import { userContext } from '../main/App'

const query = gql`
  query ($serviceID: ID!) {
    service(id: $serviceID) {
      id # need to tie the result to the correct record
      integrationKeys {
        id
        type
        name
        href
      }
    }
  }
`

const useStyles = makeStyles({
  copyIcon: {
    paddingRight: '0.25em',
    color: 'black',
  },
  keyLink: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  spacing: {
    marginBottom: 96,
  },
})

const sortItems = (a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0
}

export function IntegrationKeyDetails(props) {
  let copyText = (
    <CopyText title={'Copy ' + props.label} value={props.href} asURL />
  )

  // if link is not properly present, do not display to copy
  if (props.type === 'email' && !props.href.startsWith('mailto:')) {
    copyText = null
  }

  return (
    <React.Fragment>
      {copyText}
      {props.type === 'email' && (
        <RequireConfig
          configID='Mailgun.Enable'
          else='Email integration keys are currently disabled.'
        />
      )}
    </React.Fragment>
  )
}

IntegrationKeyDetails.propTypes = {
  href: p.string.isRequired,
  label: p.string.isRequired,
  type: p.string.isRequired,
}

export default function IntegrationKeyList({ serviceID }) {
  const classes = useStyles()

  const [create, setCreate] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(null)

  const plane = useContext(userContext)
  const isGAControlPlane = plane !== 'serviceplane'

  const { loading, error, data } = useQuery(query, {
    variables: { serviceID },
  })

  const typeLabels = {
    generic: 'Generic API Key',
    grafana: 'Grafana Webhook URL',
    site24x7: 'Site24x7 Webhook URL',
    email: 'Email Address',
    prometheusAlertmanager: 'Alertmanager Webhook URL',
  }
  if (loading && !data) return <Spinner />
  if (error) return <GenericError error={error.message} />

  if (isGAControlPlane) {
    const items = (data.service.integrationKeys || [])
      .slice()
      .sort(sortItems)
      .map((key) => ({
        title: key.name,
        subText: (
          <IntegrationKeyDetails
            key={key.id}
            href={key.href}
            label={typeLabels[key.type]}
            type={key.type}
          />
        ),
        secondaryAction: (
          <IconButton onClick={() => setDeleteDialog(key.id)} size='large'>
            <Trash />
          </IconButton>
        ),
      }))

    return (
      <React.Fragment>
        <Grid item xs={12} className={classes.spacing}>
          <IconButton
            className='ser-ib'
            size='large'
            style={{ paddingLeft: '0px' }}
          >
            <AppLink to={`/services/${serviceID}`}>
              <BackIcon className='ser-ic' />
            </AppLink>
          </IconButton>
          <Button
            variant='contained'
            aria-label='Create Integration Key'
            style={{
              textTransform: 'none',
              fontSize: '14px',
              boxShadow: 'none',
              padding: '7px 12px 7px 12px',
              margin: '0px',
              color: '#042315',
              background: '#4CDD84',
              borderRadius: '0px',
              position: 'absolute',
              right: '45px',
            }}
            color='inherit'
            size='large'
            onClick={() => setCreate(true)}
            endIcon={<ControlPointIcon />}
          >
            Create Integration Key
          </Button>
          <Card>
            <CardContent>
              <FlatList
                data-cy='int-keys'
                style={{ marginTop: '30px' }}
                headerNote={
                  <React.Fragment>
                    {/* API Documentation is available{' '}
                    <AppLink to='/docs'>here</AppLink>. */}
                  </React.Fragment>
                }
                emptyMessage='No integration keys exist for this service.'
                items={items}
              />
            </CardContent>
          </Card>
        </Grid>
        {create && (
          <IntegrationKeyCreateDialog
            serviceID={serviceID}
            onClose={() => setCreate(false)}
          />
        )}
        {deleteDialog && (
          <IntegrationKeyDeleteDialog
            integrationKeyID={deleteDialog}
            onClose={() => setDeleteDialog(null)}
          />
        )}
      </React.Fragment>
    )
  }
  const items = (data.service.integrationKeys || [])
    .slice()
    .sort(sortItems)
    .map((key) => ({
      title: key.name,
      subText: (
        <IntegrationKeyDetails
          key={key.id}
          href={key.href}
          label={typeLabels[key.type]}
          type={key.type}
        />
      ),
    }))

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.spacing}>
        <IconButton
          className='ser-ib'
          size='large'
          style={{ paddingLeft: '0px' }}
        >
          <AppLink to={`/services/${serviceID}`}>
            <BackIcon className='ser-ic' />
          </AppLink>
        </IconButton>
        <Card>
          <CardContent>
            <FlatList
              data-cy='int-keys'
              headerNote={
                <React.Fragment>
                  {/* API Documentation is available{' '}
                  <AppLink to='/docs'>here</AppLink>. */}
                </React.Fragment>
              }
              emptyMessage='No integration keys exist for this service.'
              items={items}
            />
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  )
}
