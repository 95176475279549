/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import { PropTypes as p } from 'prop-types'
import { Button, FormHelperText, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import CopyText from '../../util/CopyText'

const useStyles = makeStyles((theme) => ({
  caption: {
    width: '100%',
  },
  newTabIcon: {
    marginLeft: theme.spacing(1),
  },
  subscribeButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default function CalenderSuccessForm(props) {
  const classes = useStyles()
  const url = props.url.replace(/^https?:\/\//, 'webcal://')
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.subscribeButtonContainer}>
        <Button
          variant='contained'
          href={url}
          target='_blank'
          rel='noopener noreferrer'
          style={{
            backgroundColor: '#29707A',
            color: '#ffffff',
            borderRadius: '0px',
            boxShadow: 'none',
          }}
        >
          Subscribe
          <OpenInNewIcon fontSize='small' className={classes.newTabIcon} />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <CopyText
            title={props.url}
            value={props.url}
            placement='bottom'
            asURL
          />
        </Typography>
        <FormHelperText>
          Some applications require you copy and paste the URL directly
        </FormHelperText>
      </Grid>
    </Grid>
  )
}

CalenderSuccessForm.propTypes = {
  url: p.string.isRequired,
}
