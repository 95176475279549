/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useContext, useState } from 'react'
import { Grid, Card } from '@mui/material'
import Avatar from '@mui/material/Avatar'

import FlatList from '../../lists/FlatList'
import OtherActions from '../../util/OtherActions'
import { SlackBW } from '../../icons/components/Icons'
import { useOnCallRulesData } from './hooks'
import { onCallRuleSummary } from './util'
import ScheduleOnCallNotificationsCreateDialog from './ScheduleOnCallNotificationsCreateDialog'
import ScheduleOnCallNotificationsDeleteDialog from './ScheduleOnCallNotificationsDeleteDialog'
import ScheduleOnCallNotificationsEditDialog from './ScheduleOnCallNotificationsEditDialog'
import Button from '@mui/material/Button'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { userContext } from '../../main/App'

export type ScheduleOnCallNotificationsListProps = {
  scheduleID: string
}

export default function ScheduleOnCallNotificationsList({
  scheduleID,
}: ScheduleOnCallNotificationsListProps): JSX.Element {
  const [createRule, setCreateRule] = useState(false)
  const [editRuleID, setEditRuleID] = useState('')
  const [deleteRuleID, setDeleteRuleID] = useState('')
  const { q, zone, rules } = useOnCallRulesData(scheduleID)

  let showNotificationRule
  const plane = useContext(userContext)
  const isGAControlPlane: boolean = plane !== 'serviceplane'

  if (isGAControlPlane) {
    // return (
    showNotificationRule = (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant='contained'
              aria-label='Create Notification Rule'
              style={{
                textTransform: 'none',
                fontSize: '14px',
                boxShadow: 'none',
                padding: '7px 12px 7px 12px',
                margin: '0px',
                color: '#042315',
                background: '#4CDD84',
                borderRadius: '0px',
                position: 'absolute',
                right: '45px',
              }}
              color='inherit'
              size='large'
              onClick={() => setCreateRule(true)}
              endIcon={<ControlPointIcon />}
            >
              Create Notification Rule
            </Button>
            <Card>
              <FlatList
                style={{ marginTop: '40px' }}
                headerNote={
                  zone ? `Showing times for schedule in ${zone}.` : ''
                }
                emptyMessage={
                  q.loading
                    ? 'Loading notification rules...'
                    : 'No notification rules.'
                }
                items={rules.map((rule) => {
                  return {
                    icon:
                      rule.target.type === 'slackChannel' ? (
                        <Avatar>
                          <SlackBW />{' '}
                        </Avatar>
                      ) : null,
                    title: rule.target.name ?? undefined,
                    subText: 'Notifies ' + onCallRuleSummary(zone, rule),
                    secondaryAction: (
                      <OtherActions
                        actions={[
                          {
                            label: 'Edit',
                            onClick: () => setEditRuleID(rule.id),
                          },
                          {
                            label: 'Delete',
                            onClick: () => setDeleteRuleID(rule.id),
                          },
                        ]}
                      />
                    ),
                  }
                })}
              />
            </Card>
          </Grid>
        </Grid>
        {createRule && (
          <ScheduleOnCallNotificationsCreateDialog
            scheduleID={scheduleID}
            onClose={() => setCreateRule(false)}
          />
        )}
        {editRuleID && (
          <ScheduleOnCallNotificationsEditDialog
            scheduleID={scheduleID}
            ruleID={editRuleID}
            onClose={() => setEditRuleID('')}
          />
        )}
        {deleteRuleID && (
          <ScheduleOnCallNotificationsDeleteDialog
            scheduleID={scheduleID}
            ruleID={deleteRuleID}
            onClose={() => setDeleteRuleID('')}
          />
        )}
      </React.Fragment>
    )
  } else {
    showNotificationRule = (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <FlatList
                headerNote={
                  zone ? `Showing times for schedule in ${zone}.` : ''
                }
                emptyMessage={
                  q.loading
                    ? 'Loading notification rules...'
                    : 'No notification rules.'
                }
                items={rules.map((rule) => {
                  return {
                    icon:
                      rule.target.type === 'slackChannel' ? (
                        <Avatar>
                          <SlackBW />{' '}
                        </Avatar>
                      ) : null,
                    title: rule.target.name ?? undefined,
                    subText: 'Notifies ' + onCallRuleSummary(zone, rule),
                  }
                })}
              />
            </Card>
          </Grid>
        </Grid>
        {createRule && (
          <ScheduleOnCallNotificationsCreateDialog
            scheduleID={scheduleID}
            onClose={() => setCreateRule(false)}
          />
        )}
        {editRuleID && (
          <ScheduleOnCallNotificationsEditDialog
            scheduleID={scheduleID}
            ruleID={editRuleID}
            onClose={() => setEditRuleID('')}
          />
        )}
        {deleteRuleID && (
          <ScheduleOnCallNotificationsDeleteDialog
            scheduleID={scheduleID}
            ruleID={deleteRuleID}
            onClose={() => setDeleteRuleID('')}
          />
        )}
      </React.Fragment>
    )
  }
  return showNotificationRule
}
