/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { ReactElement, useState } from 'react'
import { useMutation } from '@apollo/client'
import { gql } from 'urql'
import { Grid, Hidden } from '@mui/material'
import {
  ArrowUpward as EscalateIcon,
  Check as AcknowledgeIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import { DateTime } from 'luxon'

import AlertsListFilter from './components/AlertsListFilter'
import AlertsListControls from './components/AlertsListControls'
import QueryList from '../lists/QueryList'
import { useURLParam } from '../actions'
import { ControlledPaginatedListAction } from '../lists/ControlledPaginatedList'
import ServiceMaintenanceNotice from '../services/ServiceMaintenanceNotice'

interface AlertsListProps {
  serviceID: string
  secondaryActions?: ReactElement
}

interface MutationVariables {
  input: MutationVariablesInput
}

interface StatusUnacknowledgedVariables {
  input: (string | number)[]
}

interface MutationVariablesInput {
  newStatus: string
  alertIDs: (string | number)[]
}

export const alertsListQuery = gql`
  query alertsList($input: AlertSearchOptions) {
    alerts(input: $input) {
      nodes {
        id
        alertID
        status
        summary
        details
        createdAt
        service {
          id
          name
        }
      }

      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

const updateMutation = gql`
  mutation UpdateAlertsMutation($input: UpdateAlertsInput!) {
    updateAlerts(input: $input) {
      status
      id
    }
  }
`

const escalateMutation = gql`
  mutation EscalateAlertsMutation($input: [Int!]) {
    escalateAlerts(input: $input) {
      status
      id
    }
  }
`

function getStatusFilter(s: string): string[] {
  switch (s) {
    case 'acknowledged':
      return ['StatusAcknowledged']
    case 'unacknowledged':
      return ['StatusUnacknowledged']
    case 'closed':
      return ['StatusClosed']
    case 'all':
      return [] // empty array returns all statuses
    // active is the default tab
    default:
      return ['StatusAcknowledged', 'StatusUnacknowledged']
  }
}

export default function AlertsList(props: AlertsListProps): JSX.Element {
  const [checkedCount, setCheckedCount] = useState(0)

  // used if user dismisses snackbar before the auto-close timer finishes
  const [actionCompleteDismissed, setActionCompleteDismissed] = useState(true)

  const [allServices] = useURLParam('allServices', true)
  // const [fullTime] = useURLParam('fullTime', false)
  const [filter] = useURLParam<string>('filter', 'all')

  // alerts list query variables
  const variables = {
    input: {
      filterByStatus: getStatusFilter(filter),
      first: 25,
      // default to favorites only, unless viewing alerts from a service's page
      favoritesOnly: !props.serviceID && !allServices,
      includeNotified: !props.serviceID, // keep service list alerts specific to that service,
      filterByServiceID: props.serviceID ? [props.serviceID] : null,
    },
  }

  const [mutate, status] = useMutation(updateMutation)

  const makeUpdateAlerts =
    (newStatus: string) => (alertIDs: (string | number)[]) => {
      setCheckedCount(alertIDs.length)
      setActionCompleteDismissed(false)

      let mutation = updateMutation
      let variables: MutationVariables | StatusUnacknowledgedVariables = {
        input: { newStatus, alertIDs },
      }

      if (newStatus === 'StatusUnacknowledged') {
        mutation = escalateMutation
        variables = { input: alertIDs }
      }

      mutate({ mutation, variables })
    }

  let updateMessage, errorMessage
  if (status.error && !status.loading) {
    errorMessage = status.error.message
  }

  if (status.data && !status.loading) {
    const numUpdated =
      status.data.updateAlerts?.length ||
      status.data.escalateAlerts?.length ||
      0

    updateMessage = `${numUpdated} of ${checkedCount} alert${
      checkedCount === 1 ? '' : 's'
    } updated`
  }

  const showAlertActionSnackbar = Boolean(
    !actionCompleteDismissed && (errorMessage || updateMessage),
  )

  /*
   * Gets the header to display above the list to give a quick overview
   * on if they are viewing alerts for all services or only their
   * favorited services.
   *
   * Possibilities:
   *   - Home page, showing alerts for all services
   *   - Home page, showing alerts for any favorited services and notified alerts
   *   - Services page, alerts for that service
   */
  function getHeaderNote(): any {
    let value
    // const { favoritesOnly, includeNotified } = variables.input

    // if (includeNotified && favoritesOnly) {
    //   return `Showing ${filter} alerts you are on-call for and from any services you have favorited.`
    // }

    // if (allServices) {
    //   return `Showing ${filter} alerts for all services.`
    // }

    // if (props.serviceID && serviceNameQuery.data?.service?.name) {
    //   return `Showing ${filter} alerts for the service ${serviceNameQuery.data.service.name}.`
    // }
    return value
  }

  function getServiceId(): any {
    const serviceValue = { serviceID: props.serviceID }
    return serviceValue
  }
  /*
   * Passes the proper actions to ListControls depending
   * on which tab is currently filtering the alerts list
   */
  function getActions(): ControlledPaginatedListAction[] {
    const actions: ControlledPaginatedListAction[] = []

    if (filter === 'unacknowledged' || filter === 'active') {
      actions.push({
        icon: <AcknowledgeIcon />,
        label: 'Acknowledge',
        onClick: makeUpdateAlerts('StatusAcknowledged'),
      })
    }

    if (filter !== 'closed') {
      actions.push(
        {
          icon: <CloseIcon />,
          label: 'Close',
          onClick: makeUpdateAlerts('StatusClosed'),
        },
        {
          icon: <EscalateIcon />,
          label: 'Response',
          onClick: makeUpdateAlerts('StatusUnacknowledged'),
        },
      )
    }

    return actions
  }

  // render
  return (
    <React.Fragment>
      <React.Fragment>
        <Grid container direction='column' spacing={2}>
          <ServiceMaintenanceNotice serviceID={props.serviceID} />
          <Grid item>
            <div
              style={{
                color: '#161616',
                fontSize: '20px',
                paddingLeft: '0px',
                paddingBottom: '0.5em',
              }}
            >
              Incidents
            </div>
            <QueryList
              query={alertsListQuery}
              infiniteScroll
              headerNote={getHeaderNote()}
              mapDataNode={(a) => ({
                id: a.id,
                status: a.status,
                ticketNo: a.alertID,
                details: a.summary,
                type: a.service.name,
                summary: a.summary,
                name: a.service.name,
                createdAt: a.createdAt,
                fullTimeStamp: DateTime.fromISO(a.createdAt).toLocaleString(
                  DateTime.DATETIME_MED,
                ),
                title: `${a.alertID}: ${a.status
                  .toUpperCase()
                  .replace('STATUS', '')}`,
                url: `/services/${a.service.id}/alerts/${a.id}`,
                selectable: a.status !== 'StatusClosed',
              })}
              variables={variables}
              alertProps={getServiceId()}
              secondaryActions={
                props?.secondaryActions ?? (
                  <AlertsListFilter serviceID={props.serviceID} />
                )
              }
              cardHeader={
                <Hidden smDown>
                  <AlertsListControls />
                </Hidden>
              }
              checkboxActions={getActions()}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    </React.Fragment>
  )
}
