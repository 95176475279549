/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { GenericError, ObjectNotFound } from '../../error-pages'
import Spinner from '../../loading/components/Spinner'
import AlertDetails from '../components/AlertDetails'

const query = gql`
  query AlertDetailsPageQuery($id: Int!) {
    alert(id: $id) {
      id
      alertID
      status
      summary
      details
      createdAt
      service {
        id
        name
        maintenanceExpiresAt
        escalationPolicy {
          id
          repeat
          steps {
            delayMinutes
            targets {
              id
              type
              name
            }
          }
        }
      }
      state {
        lastEscalation
        stepNumber
        repeatCount
      }
      pendingNotifications {
        destination
      }
    }
  }
`

function AlertDetailPage({ alertID }) {
  const { loading, error, data } = useQuery(query, {
    variables: { id: alertID },
  })

  if (!data && loading) return <Spinner />
  if (error) return <GenericError error={error.message} />
  if (!data.alert) return <ObjectNotFound type='alert' />

  return <AlertDetails data={data.alert} />
}

export default AlertDetailPage
