/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, {createContext, useLayoutEffect, useState } from 'react'
import ErrorBoundary from './ErrorBoundary'
import Grid from '@mui/material/Grid'
import { useSelector } from 'react-redux'
import { authSelector } from '../selectors'
import { PageActionProvider } from '../util/PageActions'
import LazyNewUserSetup from './components/NewUserSetup'
import Login from './components/Login'
import { SearchProvider } from '../util/AppBarSearchContainer'
import makeStyles from '@mui/styles/makeStyles'
import { useIsWidthDown } from '../util/useWidth'
import { Theme } from '@mui/material/styles'
import AppRoutes from './AppRoutes'
import { useURLKey } from '../actions'
import AuthLink from './components/AuthLink'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    backgroundColor: 'white',
    height: '100%',
  },
  main: {
    width: '100%',
    overflowY: 'auto',
    marginTop: '0px',
  },
  mainContainer: { position: 'relative', height: '100%' },
  appBar: {
    [theme.breakpoints.up('md')]: { width: '100%' },
    zIndex: theme.zIndex.drawer + 1,
  },
  containerClass: {
    padding: '30px',
    [theme.breakpoints.up('md')]: { width: '100%' },
    [theme.breakpoints.down('md')]: { width: '100%' },
  },
}))

export const userContext = createContext({})
export default function App(): JSX.Element {
  const classes = useStyles()
  const [showMobile, setShowMobile] = useState(false)
  const fullScreen = useIsWidthDown('md')
  const marginLeft = 0
  const authValid = useSelector(authSelector)
  const urlKey = useURLKey()
  
  const [appMode, setAppMode] = useState('serviceplane')
  window.addEventListener('message', (event) => {
    console.log(
      `Data Incidents Message received from the parent: ${event.data}`,
    )
    if (event.data === 'DIcontrolplane') {
      setAppMode('controlplane')
    }
  })


  useLayoutEffect(() => {
    setShowMobile(false)
  }, [urlKey])

  if (!authValid) {
    return (
      <div className={classes.root}>
        <Login />
      </div>
    )
  }

  let cyFormat = 'wide'
  if (fullScreen) cyFormat = 'mobile'
  return (
    <userContext.Provider value={appMode}>
      <div className={classes.root} id='app-root'>
        <PageActionProvider>
          <SearchProvider>
            <main
              id='content'
              className={classes.main}
              style={{ marginLeft, overflowX: 'hidden' }}
            >
              <ErrorBoundary>
                <LazyNewUserSetup />
                <AuthLink />
                <Grid
                  container
                  justifyContent='center'
                  className={classes.mainContainer}
                >
                  <Grid className={classes.containerClass} item>
                    <AppRoutes />
                  </Grid>
                </Grid>
              </ErrorBoundary>
            </main>
          </SearchProvider>
        </PageActionProvider>
      </div>
    </userContext.Provider>
  )
}
