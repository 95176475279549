/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useState, ReactElement } from 'react'
import QueryList, { QueryListProps } from './QueryList'
// import CreateFAB from './CreateFAB'

interface SimpleListPageProps extends QueryListProps {
  createForm: ReactElement
  createLabel: string
}

export default function SimpleListPage(
  props: SimpleListPageProps,
): JSX.Element {
  const { createForm, createLabel, ...rest } = props
  const [create, setCreate] = useState(false)

  return (
    <React.Fragment>
      <QueryList {...rest} />

      {createForm && (
        // <CreateFAB
        //   onClick={() => setCreate(true)}
        //   title={`Create ${createLabel}`}
        <React.Fragment />
      )}

      {create &&
        React.cloneElement(createForm, {
          onClose: () => setCreate(false),
        })}
    </React.Fragment>
  )
}
