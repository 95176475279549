/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useContext, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import FlatList from '../lists/FlatList'
import { Card, Grid } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { startCase, sortBy } from 'lodash'
import { RotationAvatar, UserAvatar } from '../util/avatars'
import OtherActions from '../util/OtherActions'
import ScheduleRuleCreateDialog from './ScheduleRuleCreateDialog'
import { ruleSummary } from './util'
import ScheduleRuleEditDialog from './ScheduleRuleEditDialog'
import ScheduleRuleDeleteDialog from './ScheduleRuleDeleteDialog'
import { GenericError } from '../error-pages'
import Spinner from '../loading/components/Spinner'
import { DateTime } from 'luxon'
import { useScheduleTZ } from './useScheduleTZ'
import Button from '@mui/material/Button'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { ArrowBack as BackIcon } from '@mui/icons-material'
import AppLink from '../util/AppLink'
import '../util/schedules.css'
import IconButton from '@mui/material/IconButton'
import { userContext } from '../main/App'

const query = gql`
  query scheduleRules($id: ID!) {
    schedule(id: $id) {
      id
      timeZone
      targets {
        target {
          id
          type
          name
        }
        rules {
          id
          start
          end
          weekdayFilter
        }
      }
    }
  }
`

export default function ScheduleRuleList({ scheduleID }) {
  const [editTarget, setEditTarget] = useState(null)
  const [deleteTarget, setDeleteTarget] = useState(null)
  const [createType, setCreateType] = useState(null)

  const { data, loading, error } = useQuery(query, {
    variables: { id: scheduleID },
    pollInterval: 0,
  })
  const { isLocalZone } = useScheduleTZ(scheduleID)

  if (error) {
    return <GenericError error={error.message} />
  }

  if (loading && !data) {
    return <Spinner />
  }

  function renderSubText(rules, timeZone) {
    const tzSummary = ruleSummary(rules, timeZone, timeZone)
    const tzAbbr = DateTime.local({ zone: timeZone }).toFormat('ZZZZ')
    const localTzSummary = ruleSummary(rules, timeZone, 'local')
    const localTzAbbr = DateTime.local({ zone: 'local' }).toFormat('ZZZZ')

    if (tzSummary === 'Always' || tzSummary === 'Never') {
      return tzSummary
    }

    return isLocalZone ? (
      <span aria-label='subtext'>{`${tzSummary} ${tzAbbr}`}</span>
    ) : (
      <Tooltip
        title={localTzSummary + ` ${localTzAbbr}`}
        placement='bottom-start'
        PopperProps={{
          'aria-label': 'local-timezone-tooltip',
        }}
      >
        <span aria-label='subtext'>{`${tzSummary} ${tzAbbr}`}</span>
      </Tooltip>
    )
  }

  function renderList(targets, timeZone) {
    const plane = useContext(userContext)
    const isGAControlPlane = plane !== 'serviceplane'
    if (isGAControlPlane) {
      const items = []

      let lastType
      sortBy(targets, ['target.type', 'target.name']).forEach((tgt) => {
        const { name, id, type } = tgt.target
        if (type !== lastType) {
          items.push({ subHeader: startCase(type + 's') })
          lastType = type
        }

        items.push({
          title: name,
          // url: (type === 'rotation' ? '/rotations/' : '/users/') + id,
          url: type === 'rotation' ? `/rotations/${id}` : '',
          subText: renderSubText(tgt.rules, timeZone),
          icon:
            type === 'rotation' ? (
              <RotationAvatar />
            ) : (
              <UserAvatar userID={id} />
            ),
          secondaryAction: (
            <OtherActions
              actions={[
                {
                  label: 'Edit',
                  onClick: () => setEditTarget({ type, id }),
                },
                {
                  label: 'Delete',
                  onClick: () => setDeleteTarget({ type, id }),
                },
              ]}
            />
          ),
        })
      })

      return (
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IconButton
                className='schedule-ib'
                size='large'
                style={{ paddingLeft: '0px' }}
              >
                <AppLink to={`/schedules/${scheduleID}`}>
                  <BackIcon className='schedule-ic' />
                </AppLink>
              </IconButton>
              <Button
                variant='contained'
                aria-label='Add Rotation'
                style={{
                  textTransform: 'none',
                  fontSize: '14px',
                  boxShadow: 'none',
                  padding: '7px 12px 7px 12px',
                  margin: '0px',
                  color: '#042315',
                  background: '#4CDD84',
                  borderRadius: '0px',
                  position: 'absolute',
                  right: '150px',
                }}
                color='inherit'
                size='large'
                onClick={() => setCreateType('rotation')}
                endIcon={<ControlPointIcon />}
              >
                Add Rotation
              </Button>
              <Button
                variant='contained'
                aria-label='Add User'
                style={{
                  textTransform: 'none',
                  fontSize: '14px',
                  boxShadow: 'none',
                  padding: '7px 12px 7px 12px',
                  marginLeft: '10px',
                  color: '#042315',
                  background: '#4CDD84',
                  borderRadius: '0px',
                  position: 'absolute',
                  right: '30px',
                }}
                color='inherit'
                size='large'
                onClick={() => setCreateType('user')}
                endIcon={<ControlPointIcon />}
              >
                Add User
              </Button>
              <Card
                style={{ width: '100%', marginBottom: 64, marginTop: '50px' }}
              >
                <FlatList
                  headerNote={`Showing times in ${data.schedule.timeZone}.`}
                  items={items}
                />
              </Card>

              {createType && (
                <ScheduleRuleCreateDialog
                  targetType={createType}
                  scheduleID={scheduleID}
                  onClose={() => setCreateType(null)}
                />
              )}
              {editTarget && (
                <ScheduleRuleEditDialog
                  target={editTarget}
                  scheduleID={scheduleID}
                  onClose={() => setEditTarget(null)}
                />
              )}
              {deleteTarget && (
                <ScheduleRuleDeleteDialog
                  target={deleteTarget}
                  scheduleID={scheduleID}
                  onClose={() => setDeleteTarget(null)}
                />
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      )
    }
    const items = []
    let lastType
    sortBy(targets, ['target.type', 'target.name']).forEach((tgt) => {
      const { name, id, type } = tgt.target
      if (type !== lastType) {
        items.push({ subHeader: startCase(type + 's') })
        lastType = type
      }
      items.push({
        title: name,
        // url: (type === 'rotation' ? '/rotations/' : '/users/') + id,
        url: type === 'rotation' ? `/rotations/${id}` : '',
        subText: renderSubText(tgt.rules, timeZone),
        icon:
          type === 'rotation' ? <RotationAvatar /> : <UserAvatar userID={id} />,
      })
    })
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <IconButton
              className='schedule-ib'
              size='large'
              style={{ paddingLeft: '0px' }}
            >
              <AppLink to={`/schedules/${scheduleID}`}>
                <BackIcon className='schedule-ic' />
              </AppLink>
            </IconButton>
            <Card style={{ width: '100%', marginBottom: 64 }}>
              <FlatList
                headerNote={`Showing times in ${data.schedule.timeZone}.`}
                items={items}
              />
            </Card>
            {createType && (
              <ScheduleRuleCreateDialog
                targetType={createType}
                scheduleID={scheduleID}
                onClose={() => setCreateType(null)}
              />
            )}
            {editTarget && (
              <ScheduleRuleEditDialog
                target={editTarget}
                scheduleID={scheduleID}
                onClose={() => setEditTarget(null)}
              />
            )}
            {deleteTarget && (
              <ScheduleRuleDeleteDialog
                target={deleteTarget}
                scheduleID={scheduleID}
                onClose={() => setDeleteTarget(null)}
              />
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  return renderList(data.schedule.targets, data.schedule.timeZone)
}
