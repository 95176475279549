/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import { SET_SHOW_NEW_USER_FORM } from '../actions'
import { getParameterByName } from '../util/query_param'

const initialState = {
  isFirstLogin: getParameterByName('isFirstLogin') === '1',
}

/*
 * Updates state depending on what action type given
 *
 * Returns the immutable final state afterwards (reduce)
 */
export default function mainReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SHOW_NEW_USER_FORM:
      return {
        ...state,
        isFirstLogin: action.payload,
      }
    default:
      return state
  }
}
