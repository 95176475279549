/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import p from 'prop-types'

const scheduleShape = p.shape({
  timeZone: p.string,
  users: p.array,
  rotation: p.shape({
    startDate: p.string,
    type: p.string,
  }),
  followTheSunRotation: p.shape({
    enable: p.string,
    users: p.array,
    timeZone: p.string,
  }),
})

export const value = p.shape({
  teamName: p.string,
  primarySchedule: scheduleShape,
  secondarySchedule: scheduleShape,
  delayMinutes: p.string,
  repeat: p.string,
  key: p.shape({
    label: p.string,
    value: p.string,
  }),
}).isRequired
