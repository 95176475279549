/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import { AlertCountSeries } from './useAdminAlertCounts'

function formatCSVField(data: string): string {
  if (!/[,"\r\n]/.test(data)) return data

  return `"${data.replace(/"/g, '""')}"`
}

export type useAlertCSVOpts = {
  alertCounts: AlertCountSeries[]
  urlPrefix: string
}

export function useAlertCountCSV({
  urlPrefix,
  alertCounts,
}: useAlertCSVOpts): string {
  let data = ''
  const cols = [`Service Name`, `Service URL`, `Total`, `Max`, `Average`]
  data += cols.map(formatCSVField).join(',') + '\r\n'

  const rows = alertCounts.map((svc) => {
    return (
      [
        svc.serviceName || '',
        `${urlPrefix}/services/${svc.id}`,
        `${svc.total}`,
        `${svc.max}`,
        `${svc.avg}`,
      ]
        .map(formatCSVField)
        .join(',') + '\r\n'
    )
  })
  return data + rows.join('')
}
