/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import AppLink from '../util/AppLink'
import { useQuery, gql } from '@apollo/client'

export const SlackChannelLink = (slackChannel) => {
  const query = gql`
    query ($id: ID!) {
      slackChannel(id: $id) {
        id
        teamID
      }
    }
  `

  const { data, loading, error } = useQuery(query, {
    variables: { id: slackChannel.id },
    fetchPolicy: 'cache-first',
  })
  const teamID = data?.slackChannel?.teamID

  if (error) {
    console.error(`Error querying slackChannel ${slackChannel.id}:`, error)
  }
  if (data && !teamID && !loading) {
    console.error('Error generating Slack link: team ID not found')
  }

  return (
    <AppLink
      to={`https://slack.com/app_redirect?channel=${slackChannel.id}&team=${teamID}`}
    >
      {slackChannel.name}
    </AppLink>
  )
}
