/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import p from 'prop-types'
import { Grid, List } from '@mui/material'
import CreateAlertListItem from './CreateAlertListItem'
import CreateAlertServiceListItem from './CreateAlertServiceListItem'

export function CreateAlertReview(props) {
  const { createdAlertIDs = [], failedServices = [] } = props

  return (
    <Grid container spacing={2}>
      {createdAlertIDs.length > 0 && (
        <Grid item xs={12}>
          <List aria-label='Successfully created alerts'>
            {createdAlertIDs.map((id) => (
              <CreateAlertListItem key={id} id={id} />
            ))}
          </List>
        </Grid>
      )}

      {failedServices.length > 0 && (
        <Grid item xs={12}>
          <List aria-label='Failed alerts'>
            {failedServices.map((svc) => {
              return (
                <CreateAlertServiceListItem
                  key={svc.id}
                  id={svc.id}
                  err={svc.message}
                />
              )
            })}
          </List>
        </Grid>
      )}
    </Grid>
  )
}

CreateAlertReview.propTypes = {
  createdAlertIDs: p.arrayOf(p.string),
  failedServices: p.arrayOf(
    p.shape({
      id: p.string,
      message: p.string,
    }),
  ),
}
