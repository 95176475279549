/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useState } from 'react'
import { gql } from '@apollo/client'

import { UserAvatar } from '../util/avatars'
import QueryList from '../lists/QueryList'
import UserPhoneNumberFilterContainer from './UserPhoneNumberFilterContainer'
// import CreateFAB from '../lists/CreateFAB'
import UserCreateDialog from './UserCreateDialog'
import { useSessionInfo } from '../util/RequireConfig'
import Button from '@mui/material/Button'
import ControlPointIcon from '@mui/icons-material/ControlPoint'

const query = gql`
  query usersQuery($input: UserSearchOptions) {
    data: users(input: $input) {
      nodes {
        id
        name
        email
        isFavorite
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

function UserList(): JSX.Element {
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const { isAdmin, ready } = useSessionInfo()

  return (
    <React.Fragment>
      <Button
        variant='contained'
        aria-label='Create User'
        style={{
          textTransform: 'none',
          fontSize: '14px',
          boxShadow: 'none',
          padding: '7px 12px 7px 12px',
          marginLeft: '10px',
          color: '#042315',
          background: '#4CDD84',
          borderRadius: '0px',
          position: 'absolute',
          right: '30px',
        }}
        color='inherit'
        size='large'
        onClick={() => setShowCreateDialog(true)}
        endIcon={<ControlPointIcon />}
      >
        Create User
      </Button>
      <QueryList
        query={query}
        variables={{ input: { favoritesFirst: true } }}
        mapDataNode={(n) => ({
          title: n.name,
          subText: n.email,
          url: n.id,
          isFavorite: n.isFavorite,
          icon: <UserAvatar userID={n.id} />,
        })}
        mapVariables={(vars) => {
          if (vars?.input.search.startsWith('phone=')) {
            vars.input.CMValue = vars.input.search.replace(/^phone=/, '')
            vars.input.search = ''
          }
          return vars
        }}
        searchAdornment={<UserPhoneNumberFilterContainer />}
      />
      {ready && isAdmin && (
        // <CreateFAB
        //   onClick={() => setShowCreateDialog(true)}
        //   title='Create User'
        // />
        <React.Fragment />
      )}
      {showCreateDialog && (
        <UserCreateDialog onClose={() => setShowCreateDialog(false)} />
      )}
    </React.Fragment>
  )
}

export default UserList
