/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import _ from 'lodash'

// shrinkWorkaround fixes bug in material where non-empty values
// fail to trigger the label text to shrink.
//
// Usage:
// const InputProps = {
//  ...otherInputProps,
//  ...shrinkWorkaround(value)
// }
export default function shrinkWorkaround(value) {
  if (_.isEmpty(value) && !_.isNumber(value)) return {}
  return { shrink: true }
}
