/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import { useAlertCSV } from '../services/AlertMetrics/useAlertCSV'
import { useAlertCountCSV } from '../admin/admin-alert-counts/useAlertCountCSV'
import { useAlertMetrics } from '../services/AlertMetrics/useAlertMetrics'
import { useAdminAlertCounts } from '../admin/admin-alert-counts/useAdminAlertCounts'

const methods = {
  useAdminAlertCounts,
  useAlertMetrics,
  useAlertCSV,
  useAlertCountCSV,
}
export default methods

export type WorkerMethodName = keyof typeof methods
export type WorkerMethod<N extends WorkerMethodName> = typeof methods[N]
export type WorkerResult<N extends WorkerMethodName> = ReturnType<
  WorkerMethod<N>
>
export type WorkerReturnType<N extends WorkerMethodName> = [
  result: WorkerResult<N>,
  status: { loading: boolean },
]
export type WorkerParam<N extends WorkerMethodName> = Parameters<
  WorkerMethod<N>
>[0]
