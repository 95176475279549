/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import { DateTime, Interval } from 'luxon'
import _ from 'lodash'
import { ExplicitZone } from './luxon-helpers'

export interface SpanISO {
  start: string
  end: string
}

export function parseInterval(s: SpanISO, zone: ExplicitZone): Interval {
  return Interval.fromDateTimes(
    DateTime.fromISO(s.start, { zone }),
    DateTime.fromISO(s.end, { zone }),
  )
}

export function trimSpans<T extends SpanISO>(
  spans: T[],
  intervals: Interval[],
  zone: ExplicitZone,
): T[] {
  intervals = Interval.merge(intervals)

  return _.flatten(
    spans.map((s) => {
      const ivl = parseInterval(s, zone)

      return ivl.difference(...intervals).map((ivl) => ({
        ...s,
        start: ivl.start.toISO(),
        end: ivl.end.toISO(),
      }))
    }),
  )
}

// isISOAfter
// Compares two ISO timestamps, returning true if `a` occurs after `b`.
export function isISOAfter(a: string, b: string): boolean {
  return DateTime.fromISO(a) > DateTime.fromISO(b)
}

// isISOBefore
// Compares two ISO timestamps, returning true if `a` occurs before `b`.
export function isISOBefore(a: string, b: string): boolean {
  return DateTime.fromISO(a) < DateTime.fromISO(b)
}
