/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
export default function getServiceLabel(input: string): {
  labelKey: string
  labelValue: string
} {
  // grab key and value from the input param, if at all
  let labelKey = ''
  let labelValue = ''
  if (input.includes('=')) {
    const searchSplit = input.split(/(!=|=)/)
    labelKey = searchSplit[0]
    // the value can contain "=", so joining the rest of the match such that it doesn't get lost
    labelValue = searchSplit.slice(2).join('')
  }

  return { labelKey, labelValue }
}
