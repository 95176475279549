/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import { FormContainer, FormField } from '../forms'
import { TextField, Grid } from '@mui/material'
import { TimeZoneSelect } from '../selection'

export interface Value {
  name: string
  description: string
  timeZone: string
  favorite?: boolean
}

interface ScheduleFormProps {
  value: Value
  onChange: (value: Value) => void

  // These can be removed when we convert FormContainer.js to typescript.
  errors?: Error[]
  disabled?: boolean
}

interface Error {
  message: string
  field: string
  helpLink?: string
}

export default function ScheduleForm(props: ScheduleFormProps): JSX.Element {
  return (
    <FormContainer optionalLabels {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormField
            fullWidth
            component={TextField}
            name='name'
            label='Name'
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            fullWidth
            component={TextField}
            multiline
            name='description'
            label='Description'
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            fullWidth
            component={TimeZoneSelect}
            name='time-zone'
            fieldName='timeZone'
            label='Time Zone'
            required
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
