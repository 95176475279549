/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
export default function joinURL(...parts: string[]): string {
  parts = parts.filter((p) => p) // remove empty segments
  if (!parts || parts.length === 0) return ''

  return parts
    .join('/')
    .replace(/\/\/+/g, '/')
    .replace(/\/$/, '')
    .split('/')
    .filter((part, idx, parts) => {
      if (idx === 0) return true
      if (part === '.' || part === '..') return false
      if (parts[idx + 1] === '..') return false
      return true
    })
    .join('/')
}
