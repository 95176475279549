/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import { gql } from '@apollo/client'
import p from 'prop-types'
import { makeQuerySelect } from './QuerySelect'

const query = gql`
  query ($input: LabelValueSearchOptions) {
    labelValues(input: $input) {
      nodes
    }
  }
`

export const LabelValueSelect = makeQuerySelect('LabelValueSelect', {
  query,
  extraVariablesFunc: ({ labelKey: key, ...props }) => [props, { key }],
  mapDataNode: (value) => ({ label: value, value }),
})
LabelValueSelect.propTypes = {
  labelKey: p.string,
}
