/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import { useMutation, gql } from '@apollo/client'
import { PropTypes as p } from 'prop-types'
import FormDialog from '../../dialogs/FormDialog'
import { nonFieldErrors } from '../../util/errutil'

const mutation = gql`
  mutation ($id: ID!) {
    deleteAll(input: [{ id: $id, type: calendarSubscription }])
  }
`

export default function CalendarSubscribeDeleteDialog(props) {
  const [deleteSubscription, status] = useMutation(mutation, {
    variables: {
      id: props.calSubscriptionID,
    },
    onCompleted: props.onClose,
  })

  return (
    <FormDialog
      title='Are you sure?'
      confirm
      loading={status.loading}
      errors={nonFieldErrors(status.error)}
      subTitle='This will delete the calendar subscription.'
      onSubmit={deleteSubscription}
      onClose={props.onClose}
    />
  )
}

CalendarSubscribeDeleteDialog.propTypes = {
  calSubscriptionID: p.string.isRequired,
  onClose: p.func.isRequired,
}
