/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import Card from '@mui/material/Card'
import makeStyles from '@mui/styles/makeStyles'
import FlatList, { FlatListListItem } from '../lists/FlatList'
import _ from 'lodash'

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
  },
}))

interface PolicyServicesCardProps {
  services: { id: string; name: string }[]
}

function PolicyServicesCard(props: PolicyServicesCardProps): JSX.Element {
  const classes = useStyles()

  function getServicesItems(): FlatListListItem[] {
    const items = props.services.map((service) => ({
      title: service.name,
      url: `/services/${service.id}`,
    }))

    // case-insensitive sort
    return _.sortBy(items, (i) => i.title.toLowerCase())
  }

  return (
    <Card className={classes.card}>
      <FlatList
        emptyMessage='No services are associated with this Response Policy.'
        items={getServicesItems()}
      />
    </Card>
  )
}

export default PolicyServicesCard
