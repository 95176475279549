/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'

import AlertsList from '../alerts/AlertsList'
import FormDialog from '../dialogs/FormDialog'
import AlertsListFilter from '../alerts/components/AlertsListFilter'
import { ArrowBack as BackIcon } from '@mui/icons-material'
import AppLink from '../util/AppLink'
import '../util/services.css'
import IconButton from '@mui/material/IconButton'

const mutation = gql`
  mutation UpdateAlertsByServiceMutation($input: UpdateAlertsByServiceInput!) {
    updateAlertsByService(input: $input)
  }
`

const useStyles = makeStyles({
  filter: {
    width: 'fit-content',
  },
  AcknowledgeCloseAll: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

export default function ServiceAlerts({ serviceID }) {
  const classes = useStyles()

  const [alertStatus, setAlertStatus] = useState('')
  const [showDialog, setShowDialog] = useState(false)
  const [mutate, mutationStatus] = useMutation(mutation, {
    variables: {
      input: {
        serviceID,
        newStatus: alertStatus,
      },
    },
    onCompleted: () => setShowDialog(false),
  })

  const { loading } = mutationStatus

  const handleClickAckAll = () => {
    setAlertStatus('StatusAcknowledged')
    setShowDialog(true)
  }

  const handleClickCloseAll = () => {
    setAlertStatus('StatusClosed')
    setShowDialog(true)
  }

  const getStatusText = () => {
    if (alertStatus === 'StatusAcknowledged') {
      return 'acknowledge'
    }

    return 'close'
  }

  const secondaryActions = (
    <Grid className={classes.filter} container spacing={2} alignItems='center'>
      <Grid item>
        <ButtonGroup variant='outlined'>
          <Button
            onClick={handleClickAckAll}
            className={classes.AcknowledgeCloseAll}
            style={{
              border: '1px solid #4CDD84',
              borderRadius: '0px',
              color: '#3D3C3C',
            }}
          >
            Acknowledge All
          </Button>
          <Button
            onClick={handleClickCloseAll}
            className={classes.AcknowledgeCloseAll}
            style={{
              border: '1px solid #4CDD84',
              borderRadius: '0px',
              color: '#3D3C3C',
            }}
          >
            Close All
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item>
        <AlertsListFilter serviceID={serviceID} />
      </Grid>
    </Grid>
  )

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <IconButton
            className='ser-ib'
            size='large'
            style={{ paddingLeft: '0px' }}
          >
            <AppLink to={`/services/${serviceID}`}>
              <BackIcon className='ser-ic' />
            </AppLink>
          </IconButton>
          {showDialog && (
            <FormDialog
              title='Are you sure?'
              confirm
              subTitle={`This will ${getStatusText()} all the incidents for this service.`}
              caption='This will stop all notifications from being sent out for all incidents with this service.'
              onSubmit={() => mutate()}
              loading={loading}
              onClose={() => setShowDialog(false)}
            />
          )}
          <AlertsList
            serviceID={serviceID}
            secondaryActions={secondaryActions}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
