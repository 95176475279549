/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useContext, useState } from 'react'
import { Card, Grid, FormControlLabel, Switch, Tooltip } from '@mui/material'
import QueryList from '../lists/QueryList'
import { gql } from '@apollo/client'
import { UserAvatar } from '../util/avatars'
import OtherActions from '../util/OtherActions'
import FilterContainer from '../util/FilterContainer'
import { UserSelect } from '../selection'
import { useURLParam, useResetURLParams } from '../actions'
import ScheduleOverrideCreateDialog from './ScheduleOverrideCreateDialog'
import ScheduleOverrideDeleteDialog from './ScheduleOverrideDeleteDialog'
import { formatOverrideTime } from './util'
import ScheduleOverrideEditDialog from './ScheduleOverrideEditDialog'
import { useScheduleTZ } from './useScheduleTZ'
import { DateTime } from 'luxon'
import Button from '@mui/material/Button'
import '../util/schedules.css'
import { ArrowBack as BackIcon } from '@mui/icons-material'
import AppLink from '../util/AppLink'
import IconButton from '@mui/material/IconButton'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import { userContext } from '../main/App'

// the query name `scheduleOverrides` is used for refetch queries
const query = gql`
  query scheduleOverrides($input: UserOverrideSearchOptions) {
    userOverrides(input: $input) {
      nodes {
        id
        start
        end
        addUser {
          id
          name
        }
        removeUser {
          id
          name
        }
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export default function ScheduleOverrideList({ scheduleID }) {
  const [editID, setEditID] = useState(null)
  const [deleteID, setDeleteID] = useState(null)
  const [create, setCreate] = useState(null)

  const [userFilter, setUserFilter] = useURLParam('userFilter', [])
  const [showPast, setShowPast] = useURLParam('showPast', false)
  const now = React.useMemo(() => new Date().toISOString(), [showPast])
  const resetFilter = useResetURLParams('userFilter', 'showPast', 'tz')

  const { zone, isLocalZone } = useScheduleTZ(scheduleID)

  const subText = (n) => {
    const tzTimeStr = formatOverrideTime(n.start, n.end, zone)
    const tzAbbr = DateTime.local({ zone }).toFormat('ZZZZ')
    const localTimeStr = formatOverrideTime(n.start, n.end, 'local')
    const localAbbr = DateTime.local({ zone: 'local' }).toFormat('ZZZZ')

    let tzSubText
    let localSubText
    if (n.addUser && n.removeUser) {
      // replace
      tzSubText = `Replaces ${n.removeUser.name} from ${tzTimeStr} ${tzAbbr}`
      localSubText = `Replaces ${n.removeUser.name} from ${localTimeStr} ${localAbbr}`
    } else if (n.addUser) {
      // add
      tzSubText = `Added from ${tzTimeStr} ${tzAbbr}`
      localSubText = `Added from ${localTimeStr} ${localAbbr}`
    } else {
      // remove
      tzSubText = `Removed from ${tzTimeStr} ${tzAbbr}`
      localSubText = `Removed from ${localTimeStr} ${localAbbr}`
    }

    return isLocalZone ? (
      <span>{localSubText}</span>
    ) : (
      <Tooltip
        title={localSubText}
        placement='bottom-start'
        PopperProps={{
          'aria-label': 'local-timezone-tooltip',
        }}
      >
        <span>{tzSubText}</span>
      </Tooltip>
    )
  }

  const hasUsers = Boolean(userFilter.length)
  const note = showPast
    ? `Showing all overrides${
        hasUsers ? ' for selected users' : ''
      } in ${zone}.`
    : `Showing active and future overrides${
        hasUsers ? ' for selected users' : ''
      } in ${zone}.`

  const plane = useContext(userContext)
  const isGAControlPlane = plane !== 'serviceplane'
  if (isGAControlPlane) {
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <IconButton
              className='schedule-ib'
              size='large'
              style={{ paddingLeft: '0px' }}
            >
              <AppLink to={`/schedules/${scheduleID}`}>
                <BackIcon className='schedule-ic' />
              </AppLink>
            </IconButton>
            <Button
              variant='contained'
              aria-label='Temporarily Replace a User'
              style={{
                textTransform: 'none',
                fontSize: '14px',
                boxShadow: 'none',
                padding: '7px 12px 7px 12px',
                margin: '0px',
                color: '#042315',
                background: '#4CDD84',
                borderRadius: '0px',
                position: 'absolute',
                right: '480px',
              }}
              color='inherit'
              size='large'
              onClick={() => setCreate('replace')}
              endIcon={<PublishedWithChangesIcon />}
            >
              Temporarily Replace a User
            </Button>
            <Button
              variant='contained'
              aria-label='Temporarily Remove a User'
              style={{
                textTransform: 'none',
                fontSize: '14px',
                boxShadow: 'none',
                padding: '7px 12px 7px 12px',
                margin: '0px',
                color: '#042315',
                background: '#4CDD84',
                borderRadius: '0px',
                position: 'absolute',
                right: '240px',
              }}
              color='inherit'
              size='large'
              onClick={() => setCreate('remove')}
              endIcon={<RemoveCircleOutlineIcon />}
            >
              Temporarily Remove a User
            </Button>
            <Button
              variant='contained'
              aria-label='Temporarily Add a User'
              style={{
                textTransform: 'none',
                fontSize: '14px',
                boxShadow: 'none',
                padding: '7px 12px 7px 12px',
                marginLeft: '10px',
                color: '#042315',
                background: '#4CDD84',
                borderRadius: '0px',
                position: 'absolute',
                right: '30px',
              }}
              color='inherit'
              size='large'
              onClick={() => setCreate('add')}
              endIcon={<ControlPointIcon />}
            >
              Temporarily Add a User
            </Button>
            <Card style={{ marginTop: '40px' }}>
              <QueryList
                headerNote={note}
                noSearch
                noPlaceholder
                query={query}
                mapDataNode={(n) => ({
                  title: n.addUser ? n.addUser.name : n.removeUser.name,
                  subText: subText(n),
                  icon: (
                    <UserAvatar
                      userID={n.addUser ? n.addUser.id : n.removeUser.id}
                    />
                  ),
                  action: (
                    <OtherActions
                      actions={[
                        {
                          label: 'Edit',
                          onClick: () => setEditID(n.id),
                        },
                        {
                          label: 'Delete',
                          onClick: () => setDeleteID(n.id),
                        },
                      ]}
                    />
                  ),
                })}
                variables={{
                  input: {
                    scheduleID,
                    start: showPast ? null : now,
                    filterAnyUserID: userFilter,
                  },
                }}
                headerAction={
                  <FilterContainer onReset={() => resetFilter()}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={showPast}
                            onChange={(e) => setShowPast(e.target.checked)}
                            value='showPast'
                          />
                        }
                        label='Show past overrides'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <UserSelect
                        label='Filter users...'
                        multiple
                        value={userFilter}
                        onChange={(value) => setUserFilter(value)}
                      />
                    </Grid>
                  </FilterContainer>
                }
              />
            </Card>
            {create && (
              <ScheduleOverrideCreateDialog
                scheduleID={scheduleID}
                variant={create}
                onClose={() => setCreate(null)}
              />
            )}
            {deleteID && (
              <ScheduleOverrideDeleteDialog
                overrideID={deleteID}
                onClose={() => setDeleteID(null)}
              />
            )}
            {editID && (
              <ScheduleOverrideEditDialog
                overrideID={editID}
                onClose={() => setEditID(null)}
              />
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <IconButton
            className='schedule-ib'
            size='large'
            style={{ paddingLeft: '0px' }}
          >
            <AppLink to={`/schedules/${scheduleID}`}>
              <BackIcon className='schedule-ic' />
            </AppLink>
          </IconButton>
          <Card>
            <QueryList
              headerNote={note}
              noSearch
              noPlaceholder
              query={query}
              mapDataNode={(n) => ({
                title: n.addUser ? n.addUser.name : n.removeUser.name,
                subText: subText(n),
                icon: (
                  <UserAvatar
                    userID={n.addUser ? n.addUser.id : n.removeUser.id}
                  />
                ),
              })}
              variables={{
                input: {
                  scheduleID,
                  start: showPast ? null : now,
                  filterAnyUserID: userFilter,
                },
              }}
              headerAction={
                <FilterContainer onReset={() => resetFilter()}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showPast}
                          onChange={(e) => setShowPast(e.target.checked)}
                          value='showPast'
                        />
                      }
                      label='Show past overrides'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <UserSelect
                      label='Filter users...'
                      multiple
                      value={userFilter}
                      onChange={(value) => setUserFilter(value)}
                    />
                  </Grid>
                </FilterContainer>
              }
            />
          </Card>
          {create && (
            <ScheduleOverrideCreateDialog
              scheduleID={scheduleID}
              variant={create}
              onClose={() => setCreate(null)}
            />
          )}
          {deleteID && (
            <ScheduleOverrideDeleteDialog
              overrideID={deleteID}
              onClose={() => setDeleteID(null)}
            />
          )}
          {editID && (
            <ScheduleOverrideEditDialog
              overrideID={editID}
              onClose={() => setEditID(null)}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
