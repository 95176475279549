/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { ReactNode, ReactElement, forwardRef } from 'react'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useIsWidthDown } from '../util/useWidth'
import { FavoriteIcon } from '../util/SetFavoriteButton'
import { ITEMS_PER_PAGE } from '../config'
import Spinner from '../loading/components/Spinner'
import { CheckboxItemsProps } from './ControlledPaginatedList'
import AppLink, { AppLinkProps } from '../util/AppLink'
import { debug } from '../util/debug'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import '../util/rotations.css'
import '../util/services.css'
import '../util/escalationpolicies.css'
import '../util/schedules.css'
import { formatTimeSince } from '../util/timeFormat'
import { useURLParam } from '../actions'

const useStyles = makeStyles(() => ({
  infiniteScrollFooter: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0.25em 0 0.25em 0',
  },
  itemAction: {
    paddingLeft: 14,
  },
  itemText: {
    wordBreak: 'break-word',
  },
  favoriteIcon: {
    backgroundColor: 'transparent',
  },
}))

function LoadingItem(props: { dense?: boolean }): JSX.Element {
  return (
    <ListItem dense={props.dense}>
      <Skeleton variant='rectangular' animation='wave' width='100%'>
        <ListItemText primary='.' secondary='.' />
      </Skeleton>
    </ListItem>
  )
}

export interface PaginatedListProps {
  items: PaginatedListItemProps[] | CheckboxItemsProps[]
  itemsPerPage?: number

  pageCount?: number
  page?: number

  isLoading?: boolean
  loadMore?: (numberToLoad?: number) => void

  // disables the placeholder display during loading
  noPlaceholder?: boolean

  // provide a custom message to display if there are no results
  emptyMessage?: string

  // if set, loadMore will be called when the user
  // scrolls to the bottom of the list. appends list
  // items to the list rather than rendering a new page
  infiniteScroll?: boolean
}

export interface PaginatedListItemProps {
  url?: string
  title: string
  type?: string
  details: string
  ticketNo?: string
  subText?: string
  createdAt?: string
  fullTimeStamp?: string
  isFavorite?: boolean
  icon?: ReactElement // renders a list item icon (or avatar)
  action?: ReactNode
  status?: string
}

export function PaginatedList(props: PaginatedListProps): JSX.Element {
  const {
    items = [],
    itemsPerPage = ITEMS_PER_PAGE,
    pageCount,
    page,
    infiniteScroll,
    isLoading,
    loadMore,
    emptyMessage = 'No results',
    noPlaceholder,
  } = props

  const classes = useStyles()
  const fullScreen = useIsWidthDown('md')
  const [fullTime] = useURLParam('fullTime', false)

  function renderNoResults(): ReactElement {
    return (
      <ListItem>
        <ListItemText
          disableTypography
          secondary={
            <Typography
              variant='caption'
              style={{
                color: '#8F8F8F',
                font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
              }}
            >
              {emptyMessage}
            </Typography>
          }
        />
      </ListItem>
    )
  }

  function renderItem(item: PaginatedListItemProps, idx: number): ReactElement {
    let favIcon = null
    const mod = idx % 2 === 0 ? '#F8F8F8' : '#FFFFFF'
    if (item.isFavorite) {
      favIcon = (
        <div className={classes.itemAction} style={{ width: '10%' }}>
          <Avatar className={classes.favoriteIcon}>
            <FavoriteIcon />
          </Avatar>
        </div>
      )
    }

    function getStatusFilter(s: any): any {
      if (s === 'StatusAcknowledged') {
        return (
          <div style={{ display: 'flex' }}>
            <div className='dot ack' />
            <div className='ml'>Acknowledged</div>
          </div>
        )
      }
      if (s === 'StatusUnacknowledged') {
        return (
          <div style={{ display: 'flex' }}>
            <div className='dot unack' />
            <div className='ml'>Unacknowledged</div>
          </div>
        )
      }
      if (s === 'StatusClosed') {
        return (
          <div style={{ display: 'flex' }}>
            <div className='dot cls' />
            <div className='ml'>Closed</div>
          </div>
        )
      }
      return ''
    }

    const AppLinkListItem = forwardRef<HTMLAnchorElement, AppLinkProps>(
      (props, ref) => (
        <li>
          <AppLink ref={ref} {...props} />
        </li>
      ),
    )
    AppLinkListItem.displayName = 'AppLinkListItem'

    // must be explicitly set when using, in accordance with TS definitions
    const urlProps = item.url && {
      component: AppLinkListItem,

      // NOTE button: false? not assignable to true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      button: true as any,
      to: item.url,
    }

    const tabUrl = location.href.split('?')[0]

    if (tabUrl.includes('alerts')) {
      return (
        <div style={{ backgroundColor: mod }}>
          {item.action && (
            <div className={classes.itemAction}>{item.action}</div>
          )}
          <TableRow key={'list_' + idx} {...urlProps}>
            <TableCell style={{ width: '5%', color: '#525252' }}>
              {item.icon}
            </TableCell>
            <TableCell style={{ width: '10%', color: '#525252' }}>
              {item.ticketNo}
            </TableCell>
            <TableCell style={{ width: '30%', color: '#525252' }}>
              {item.details}
            </TableCell>
            <TableCell style={{ width: '25%', color: '#525252' }}>
              {item.type}
            </TableCell>
            <TableCell style={{ width: '20%', color: '#525252' }}>
              {getStatusFilter(item.status)}
            </TableCell>
            <TableCell style={{ width: '10%', color: '#525252' }}>
              {fullTime
                ? item.createdAt !== '' && item.createdAt !== undefined
                  ? item.fullTimeStamp
                  : ''
                : item.createdAt !== '' && item.createdAt !== undefined
                ? formatTimeSince(item.createdAt)
                : ''}
            </TableCell>
          </TableRow>
        </div>
      )
    }
    if (tabUrl.includes('rotations')) {
      return (
        <div
          className='rotation'
          style={{
            marginBottom: '10px',
            borderRadius: '2px',
            boxShadow: '0 0 6px #0000001a',
            transition: '.3s',
            border: '1px solid #e0e0e0',
          }}
        >
          <ListItem dense={!fullScreen} key={'list_' + idx} {...urlProps}>
            {item.icon && <ListItemAvatar>{item.icon}</ListItemAvatar>}
            <ListItemText
              className={classes.itemText}
              primary={
                <Typography
                  style={{
                    color: '#3D3C3C',
                    font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
                    marginBottom: '10px',
                    right: '0',
                  }}
                >
                  {item.title}
                </Typography>
              }
              secondary={
                <Typography
                  style={{
                    color: '#8F8F8F',
                    font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
                    right: '0',
                  }}
                >
                  {item.subText}
                </Typography>
              }
              style={{ width: '40%' }}
            />
            {favIcon}
            {item.action && (
              <div className={classes.itemAction}>{item.action}</div>
            )}
          </ListItem>
        </div>
      )
    }
    if (tabUrl.includes('schedules')) {
      return (
        <div
          className='schedule'
          style={{
            marginBottom: '10px',
            borderRadius: '2px',
            boxShadow: '0 0 6px #0000001a',
            transition: '.3s',
            border: '1px solid #e0e0e0',
          }}
        >
          <ListItem dense={!fullScreen} key={'list_' + idx} {...urlProps}>
            {item.icon && <ListItemAvatar>{item.icon}</ListItemAvatar>}
            <ListItemText
              className={classes.itemText}
              primary={
                <Typography
                  style={{
                    color: '#3D3C3C',
                    font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
                    marginBottom: '10px',
                    right: '0',
                  }}
                >
                  {item.title}
                </Typography>
              }
              secondary={
                <Typography
                  style={{
                    color: '#8F8F8F',
                    font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
                    right: '0',
                  }}
                >
                  {item.subText}
                </Typography>
              }
              style={{ width: '40%' }}
            />
            {favIcon}
            {item.action && (
              <div className={classes.itemAction}>{item.action}</div>
            )}
          </ListItem>
        </div>
      )
    }
    if (tabUrl.includes('escalation-policies')) {
      return (
        <div
          className='responsepolicy'
          style={{
            marginBottom: '10px',
            borderRadius: '2px',
            boxShadow: '0 0 6px #0000001a',
            transition: '.3s',
            border: '1px solid #e0e0e0',
          }}
        >
          <ListItem dense={!fullScreen} key={'list_' + idx} {...urlProps}>
            {item.icon && <ListItemAvatar>{item.icon}</ListItemAvatar>}
            <ListItemText
              className={classes.itemText}
              primary={
                <Typography
                  style={{
                    color: '#3D3C3C',
                    font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
                    marginBottom: '10px',
                    right: '0',
                  }}
                >
                  {item.title}
                </Typography>
              }
              secondary={
                <Typography
                  style={{
                    color: '#8F8F8F',
                    font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
                    right: '0',
                  }}
                >
                  {item.subText}
                </Typography>
              }
              style={{ width: '40%' }}
            />
            {favIcon}
            {item.action && (
              <div className={classes.itemAction}>{item.action}</div>
            )}
          </ListItem>
        </div>
      )
    }
    if (tabUrl.includes('services')) {
      return (
        <div
          className='service'
          style={{
            marginBottom: '10px',
            borderRadius: '2px',
            boxShadow: '0 0 6px #0000001a',
            transition: '.3s',
            border: '1px solid #e0e0e0',
          }}
        >
          <ListItem dense={!fullScreen} key={'list_' + idx} {...urlProps}>
            {item.icon && <ListItemAvatar>{item.icon}</ListItemAvatar>}
            <ListItemText
              className={classes.itemText}
              primary={
                <Typography
                  style={{
                    color: '#3D3C3C',
                    font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
                    marginBottom: '10px',
                    right: '0',
                  }}
                >
                  {item.title}
                </Typography>
              }
              secondary={
                <Typography
                  style={{
                    color: '#8F8F8F',
                    font: 'normal normal normal 14px/18px IBM Plex Sans, sans-serif',
                    right: '0',
                  }}
                >
                  {item.subText}
                </Typography>
              }
              style={{ width: '40%' }}
            />
            {favIcon}
            {item.action && (
              <div className={classes.itemAction}>{item.action}</div>
            )}
          </ListItem>
        </div>
      )
    }
    return (
      <ListItem dense={!fullScreen} key={'list_' + idx} {...urlProps}>
        {item.icon && <ListItemAvatar>{item.icon}</ListItemAvatar>}
        <ListItemText
          className={classes.itemText}
          primary={item.title}
          secondary={item.subText}
        />
        {favIcon}
        {item.action && <div className={classes.itemAction}>{item.action}</div>}
      </ListItem>
    )
  }

  function renderListItems(): ReactElement | ReactElement[] {
    if (pageCount === 0 && !isLoading) return renderNoResults()

    let newItems: Array<PaginatedListItemProps> = items.slice()
    if (!infiniteScroll && page !== undefined) {
      newItems = items.slice(page * itemsPerPage, (page + 1) * itemsPerPage)
    }
    const renderedItems: ReactElement[] = newItems.map(renderItem)

    // Display full list when loading
    if (!noPlaceholder && isLoading) {
      while (renderedItems.length < itemsPerPage) {
        renderedItems.push(
          <LoadingItem
            dense={!fullScreen}
            key={'list_' + renderedItems.length}
          />,
        )
      }
    }

    return renderedItems
  }

  function renderList(): ReactElement {
    const tabUrl = location.href.split('?')[0]
    if (tabUrl.includes('alerts')) {
      return (
        <List
          data-cy='apollo-list'
          style={{
            boxShadow: 'none',
            marginBottom: '0px',
            borderLeft: 'none',
            padding: '28px 0px',
          }}
        >
          <TableRow>
            <TableCell
              style={{
                width: '5%',
                fontWeight: '500',
                paddingLeft: '42px',
                color: '#042315',
              }}
            />
            <TableCell
              style={{ width: '10%', fontWeight: '500', color: '#042315' }}
            >
              Ticket No.
            </TableCell>
            <TableCell
              style={{ width: '30%', fontWeight: '500', color: '#042315' }}
            >
              Issue Details
            </TableCell>
            <TableCell
              style={{ width: '25%', fontWeight: '500', color: '#042315' }}
            >
              Issue Type
            </TableCell>
            <TableCell
              style={{ width: '20%', fontWeight: '500', color: '#042315' }}
            >
              Status
            </TableCell>
            <TableCell
              style={{ width: '10%', fontWeight: '500', color: '#042315' }}
            >
              Time
            </TableCell>
          </TableRow>
          {renderListItems()}
        </List>
      )
    }
    if (tabUrl.includes('rotations')) {
      return (
        <div style={{ width: '100%' }}>
          <List
            data-cy='apollo-list'
            style={{
              boxShadow: 'none',
              marginBottom: '0px',
              borderLeft: 'none',
              padding: '28px 0px',
            }}
          >
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    style={{
                      width: '40%',
                      paddingLeft: '10px',
                      font: 'normal normal 500 14px/18px IBM Plex Sans, sans-serif',
                      color: '#042315',
                    }}
                  >
                    Rotation Details
                  </Typography>
                }
                style={{
                  width: '40%',
                  paddingLeft: '10px',
                  font: 'normal normal 500 16px/20px IBM Plex Sans, sans-serif',
                  color: '#042315',
                }}
              />
            </ListItem>
            {renderListItems()}
          </List>
        </div>
      )
    }
    if (tabUrl.includes('schedules')) {
      return (
        <div style={{ width: '100%' }}>
          <List
            data-cy='apollo-list'
            style={{
              boxShadow: 'none',
              marginBottom: '0px',
              borderLeft: 'none',
              padding: '28px 0px',
            }}
          >
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    style={{
                      width: '40%',
                      paddingLeft: '10px',
                      font: 'normal normal 500 14px/18px IBM Plex Sans, sans-serif',
                      color: '#042315',
                    }}
                  >
                    Scheduling Details
                  </Typography>
                }
                style={{
                  width: '40%',
                  paddingLeft: '10px',
                  font: 'normal normal 500 16px/20px IBM Plex Sans, sans-serif',
                  color: '#042315',
                }}
              />
            </ListItem>
            {renderListItems()}
          </List>
        </div>
      )
    }
    if (tabUrl.includes('escalation-policies')) {
      return (
        <div style={{ width: '100%' }}>
          <List
            data-cy='apollo-list'
            style={{
              boxShadow: 'none',
              marginBottom: '0px',
              borderLeft: 'none',
              padding: '28px 0px',
            }}
          >
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    style={{
                      width: '40%',
                      paddingLeft: '10px',
                      font: 'normal normal 500 14px/18px IBM Plex Sans, sans-serif',
                      color: '#042315',
                    }}
                  >
                    Response Details
                  </Typography>
                }
                style={{
                  width: '40%',
                  paddingLeft: '10px',
                  font: 'normal normal 500 16px/20px IBM Plex Sans, sans-serif',
                  color: '#042315',
                }}
              />
            </ListItem>
            {renderListItems()}
          </List>
        </div>
      )
    }
    if (tabUrl.includes('services')) {
      return (
        <div style={{ width: '100%' }}>
          <List
            data-cy='apollo-list'
            style={{
              boxShadow: 'none',
              marginBottom: '0px',
              borderLeft: 'none',
              padding: '28px 0px',
            }}
          >
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    style={{
                      width: '40%',
                      paddingLeft: '10px',
                      font: 'normal normal 500 14px/18px IBM Plex Sans, sans-serif',
                      color: '#042315',
                    }}
                  >
                    Services Details
                  </Typography>
                }
                style={{
                  width: '40%',
                  paddingLeft: '10px',
                  font: 'normal normal 500 16px/20px IBM Plex Sans, sans-serif',
                  color: '#042315',
                }}
              />
            </ListItem>
            {renderListItems()}
          </List>
        </div>
      )
    }
    return <List data-cy='apollo-list'>{renderListItems()}</List>
  }

  function renderAsInfiniteScroll(): ReactElement {
    const len = items.length

    return (
      <InfiniteScroll
        hasMore={Boolean(loadMore)}
        next={
          loadMore ||
          (() => {
            debug('next callback missing from InfiniteScroll')
          })
        }
        scrollableTarget='content'
        endMessage={
          len === 0 ? null : (
            <Typography
              className={classes.infiniteScrollFooter}
              color='textSecondary'
              variant='body2'
            >
              Displaying all results.
            </Typography>
          )
        }
        loader={
          <div className={classes.infiniteScrollFooter}>
            <Spinner text='Loading...' />
          </div>
        }
        dataLength={len}
      >
        {renderList()}
      </InfiniteScroll>
    )
  }

  return (
    <React.Fragment>
      {infiniteScroll ? renderAsInfiniteScroll() : renderList()}
    </React.Fragment>
  )
}
