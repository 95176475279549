/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import IconButton from '@mui/material/IconButton'
import MUIFavoriteIcon from '@mui/icons-material/Star'
import NotFavoriteIcon from '@mui/icons-material/StarBorder'
import Tooltip from '@mui/material/Tooltip'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'lodash'
import { Theme } from '@mui/material'

interface SetFavoriteButtonProps {
  typeName: 'rotation' | 'service' | 'schedule' | 'escalationPolicy' | 'user'
  onClick: (event: React.FormEvent<HTMLFormElement>) => void
  isFavorite?: boolean
  loading?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  favorited: {
    color:
      theme.palette.mode === 'dark' ? theme.palette.primary.main : '#FFD953',
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  notFavorited: {
    color: '#FFD953',
    '&:focus': {
      backgroundColor: 'white',
    },
  },
}))

export function FavoriteIcon(): JSX.Element {
  const classes = useStyles()
  return <MUIFavoriteIcon data-cy='fav-icon' className={classes.favorited} />
}

export function SetFavoriteButton({
  typeName,
  onClick,
  isFavorite,
  loading,
}: SetFavoriteButtonProps): JSX.Element | null {
  const classes = useStyles()

  if (loading) {
    return null
  }

  const icon = isFavorite ? (
    <FavoriteIcon />
  ) : (
    <NotFavoriteIcon className={classes.notFavorited} />
  )

  const content = (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <IconButton
        aria-label={
          isFavorite
            ? `Unset as a Favorite ${_.startCase(typeName).toLowerCase()}`
            : `Set as a Favorite ${_.startCase(typeName).toLowerCase()}`
        }
        type='submit'
        data-cy='set-fav'
        size='large'
      >
        {icon}
      </IconButton>
    </form>
  )

  return (
    <Tooltip title={isFavorite ? '' : ''} placement='top'>
      {content}
    </Tooltip>
  )
}
