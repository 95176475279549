/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import p from 'prop-types'
import { fieldErrors, nonFieldErrors } from '../util/errutil'
import FormDialog from '../dialogs/FormDialog'
import PolicyForm from './PolicyForm'
import { Redirect } from 'wouter'

const mutation = gql`
  mutation ($input: CreateEscalationPolicyInput!) {
    createEscalationPolicy(input: $input) {
      id
    }
  }
`

function PolicyCreateDialog(props) {
  const [value, setValue] = useState(null)
  const defaultValue = {
    name: '',
    description: '',
    repeat: { label: '3', value: '3' },
    favorite: true,
  }
  const [createPolicy, createPolicyStatus] = useMutation(mutation, {
    variables: {
      input: {
        name: (value && value.name) || defaultValue.name,
        description: (value && value.description) || defaultValue.description,
        repeat: (value && value.repeat.value) || defaultValue.repeat.value,
        favorite: true,
      },
    },
    onCompleted: props.onClose,
  })

  const { loading, data, error } = createPolicyStatus

  if (data && data.createEscalationPolicy) {
    return (
      <Redirect to={`/escalation-policies/${data.createEscalationPolicy.id}`} />
    )
  }

  const fieldErrs = fieldErrors(error)

  return (
    <FormDialog
      title='Create Response Policy'
      loading={loading}
      errors={nonFieldErrors(error)}
      onClose={props.onClose}
      onSubmit={() => createPolicy()}
      form={
        <PolicyForm
          errors={fieldErrs}
          disabled={loading}
          value={value || defaultValue}
          onChange={(value) => setValue(value)}
        />
      }
    />
  )
}

PolicyCreateDialog.propTypes = {
  onClose: p.func,
}

export default PolicyCreateDialog
