/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useURLParam } from '../../actions'

const tabs = ['all', 'active', 'unacknowledged', 'acknowledged', 'closed']

function AlertsListControls(): JSX.Element {
  const [filter, setFilter] = useURLParam<string>('filter', 'all')

  let currTab = tabs.indexOf(filter)
  if (currTab === -1) currTab = 0 // handle jargon input from url params

  return (
    <Tabs
      value={currTab}
      onChange={(e, idx) => setFilter(tabs[idx])}
      TabIndicatorProps={{ style: { background: '#4CDD84' } }}
      textColor='primary'
    >
      <Tab label='All' />
      <Tab label='Active' />
      <Tab label='Unacknowledged' />
      <Tab label='Acknowledged' />
      <Tab label='Closed' />
    </Tabs>
  )
}

export default AlertsListControls
