/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import { useTheme, Breakpoint, Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export function useIsWidthUp(breakpoint: Breakpoint): boolean {
  const theme = useTheme() as Theme
  return useMediaQuery(theme.breakpoints.up(breakpoint))
}

export function useIsWidthDown(breakpoint: Breakpoint): boolean {
  const theme = useTheme() as Theme
  return useMediaQuery(theme.breakpoints.down(breakpoint))
}
