/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import { gql, useQuery } from 'urql'
import PolicyServicesCard from './PolicyServicesCard'
import Spinner from '../loading/components/Spinner'
import { GenericError, ObjectNotFound } from '../error-pages'
import { Grid } from '@mui/material'
import { ArrowBack as BackIcon } from '@mui/icons-material'
import AppLink from '../util/AppLink'
import '../util/escalationpolicies.css'
import IconButton from '@mui/material/IconButton'

const query = gql`
  query ($id: ID!) {
    escalationPolicy(id: $id) {
      id
      assignedTo {
        id
        name
      }
    }
  }
`

function PolicyServicesQuery(props: { policyID: string }): JSX.Element {
  const [{ data, fetching, error }] = useQuery({
    query,
    variables: { id: props.policyID },
  })

  if (!data && fetching) {
    return <Spinner />
  }

  if (error) {
    return <GenericError error={error.message} />
  }

  if (!data.escalationPolicy) {
    return <ObjectNotFound />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <IconButton
          className='escpol-ib'
          size='large'
          style={{ paddingLeft: '0px' }}
        >
          <AppLink to={`/escalation-policies/${props.policyID}`}>
            <BackIcon className='escpol-ic' />
          </AppLink>
        </IconButton>
        <PolicyServicesCard services={data.escalationPolicy.assignedTo || []} />
      </Grid>
    </Grid>
  )
}

export default PolicyServicesQuery
