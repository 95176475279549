/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { ReactElement, ReactNode, useContext, useState } from 'react'
import { Card, Checkbox, Grid, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  PaginatedList,
  PaginatedListItemProps,
  PaginatedListProps,
} from './PaginatedList'
import { ListHeaderProps } from './ListHeader'
import Search from '../util/Search'
import { useURLKey } from '../actions'
import { useMutation, gql } from '@apollo/client'
import Button from '@mui/material/Button'
import RotationCreateDialog from '../rotations/RotationCreateDialog'
import PolicyCreateDialog from '../escalation-policies/PolicyCreateDialog'
import ScheduleCreateDialog from '../schedules/ScheduleCreateDialog'
import ServiceCreateDialog from '../services/ServiceCreateDialog'
import CreateAlertDialog from '../alerts/CreateAlertDialog/CreateAlertDialog'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { userContext } from '../main/App'
interface AlertsListProps {
  serviceID: string
}
export const alertsListQuery = gql`
  query alertsList($input: AlertSearchOptions) {
    alerts(input: $input) {
      nodes {
        id
        alertID
        status
        summary
        details
        createdAt
        service {
          id
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
const updateMutation = gql`
  mutation UpdateAlertsMutation($input: UpdateAlertsInput!) {
    updateAlerts(input: $input) {
      status
      id
    }
  }
`

const useStyles = makeStyles({
  actionsContainer: {
    alignItems: 'center',
    display: 'flex',
    marginRight: 'auto',
    paddingLeft: '1em', // align with listItem icons
    width: 'fit-content',
  },
  checkbox: {
    marginTop: 4,
    marginBottom: 4,
  },
  controlsContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  popper: {
    opacity: 1,
  },
  search: {
    paddingLeft: '0.5em',
  },
})

export interface ControlledPaginatedListProps
  extends PaginatedListProps,
    ListHeaderProps {
  listHeader?: ReactNode
  alertProps: AlertsListProps

  checkboxActions?: ControlledPaginatedListAction[]
  secondaryActions?: ReactElement

  // if set, the search string param is ignored
  noSearch?: boolean

  // filters additional to search, set in the search text field
  searchAdornment?: ReactElement

  items: CheckboxItemsProps[] | PaginatedListItemProps[]
}

export interface ControlledPaginatedListAction {
  // icon for the action (e.g. X for close)
  icon: ReactElement

  // label to display (e.g. "Close")
  label: string

  // Callback that will be passed a list of selected items
  onClick: (selectedIDs: (string | number)[]) => void
}

// used if checkBoxActions is set to true
export interface CheckboxItemsProps extends PaginatedListItemProps {
  // used to track checked items
  id: string | number

  /*
   * if false, checkbox will be disabled, and if already selected
   * it will be omitted from the action callback.
   *
   * defaults to true
   */
  selectable?: boolean
}

export default function ControlledPaginatedList(
  props: ControlledPaginatedListProps,
): JSX.Element {
  const classes = useStyles()
  const {
    checkboxActions,
    secondaryActions,
    noSearch,
    searchAdornment,
    items,
    listHeader,
    ...listProps
  } = props

  // const isXs = useIsWidthDown('sm')
  const [checkedCount] = useState(0)
  const [showCreate, setShowCreate] = useState(false)
  const [mutate, status] = useMutation(updateMutation)
  const [create, setCreate] = useState(false)
  const tabUrl = location.href.split('?')[0]
  let createFormvalue: any
  const plane = useContext(userContext)
  const isGAControlPlane: boolean = plane !== 'serviceplane'
  let createLabel: any
  if (tabUrl.includes('rotations')) {
    createFormvalue = <RotationCreateDialog />
    createLabel = 'Rotation'
  } else if (tabUrl.includes('schedules')) {
    createFormvalue = <ScheduleCreateDialog />
    createLabel = 'New Schedules'
  } else if (tabUrl.includes('escalation-policies')) {
    createFormvalue = <PolicyCreateDialog />
    createLabel = 'Response Policies'
  } else if (tabUrl.includes('services')) {
    createFormvalue = <ServiceCreateDialog />
    createLabel = 'New Service'
  }
  // used if user dismisses snackbar before the auto-close timer finishes
  const [actionCompleteDismissed, setActionCompleteDismissed] = useState(true)
  let updateMessage, errorMessage
  if (status.error && !status.loading) {
    errorMessage = status.error.message
  }
  if (status.data && !status.loading) {
    const numUpdated =
      status.data.updateAlerts?.length ||
      status.data.escalateAlerts?.length ||
      0
    updateMessage = `${numUpdated} of ${checkedCount} alert${
      checkedCount === 1 ? '' : 's'
    } updated`
  }
  const showAlertActionSnackbar = Boolean(
    !actionCompleteDismissed && (errorMessage || updateMessage),
  )

  /*
   * ensures item type is of CheckboxItemsProps and not PaginatedListItemProps
   * checks all items against having no id present
   * returns true if all items have an id
   */
  function itemsHaveID(
    items: CheckboxItemsProps[] | PaginatedListItemProps[],
  ): items is CheckboxItemsProps[] {
    return !items.some(
      (i: CheckboxItemsProps | PaginatedListItemProps) => !('id' in i),
    )
  }

  function getSelectableIDs(): Array<string | number> {
    if (itemsHaveID(items)) {
      return items.filter((i) => i.selectable !== false).map((i) => i.id)
    }
    return []
  }

  const [_checkedItems, setCheckedItems] = useState<Array<string | number>>([])
  // covers the use case where an item may no longer be selectable after an update
  const checkedItems = _checkedItems.filter((id) =>
    getSelectableIDs().includes(id),
  )
  const urlKey = useURLKey()

  function setAll(): void {
    setCheckedItems(getSelectableIDs())
  }

  function setNone(): void {
    setCheckedItems([])
  }

  function handleToggleSelectAll(): void {
    // if none are checked, set all
    if (checkedItems.length === 0) {
      setAll()
    } else {
      setNone()
    }
  }

  function renderActions(): ReactElement | null {
    if (!checkboxActions) return null
    const itemIDs = getSelectableIDs()

    return (
      <Grid
        aria-label='List Checkbox Controls'
        className={classes.actionsContainer}
        item
        container
        spacing={2}
      >
        <Grid item style={{ paddingRight: '5px', paddingLeft: '0px' }}>
          <Button
            variant='contained'
            aria-label='Filter Alerts'
            style={{
              textTransform: 'none',
              color: '#525252',
              backgroundColor: '#f4f4f4',
              fontSize: '14px',
              boxShadow: 'none',
              padding: '5px 18px 5px 18px',
              margin: '0px',
              borderRadius: '0px',
            }}
            size='large'
          >
            <Checkbox
              style={{
                width: '22px',
                height: '22px',
                padding: '0px 12px 0px 0px',
              }}
              className={classes.checkbox}
              checked={
                itemIDs.length === checkedItems.length && itemIDs.length > 0
              }
              data-cy='select-all'
              indeterminate={
                checkedItems.length > 0 &&
                itemIDs.length !== checkedItems.length
              }
              onChange={handleToggleSelectAll}
            />
            Select
          </Button>
        </Grid>
        {checkedItems.length > 0 &&
          checkboxActions.map((a, idx) => (
            <Grid
              item
              key={idx}
              style={{ paddingRight: '5px', paddingLeft: '0px' }}
            >
              <Tooltip
                title={a.label}
                placement='bottom'
                classes={{ popper: classes.popper }}
              >
                <Button
                  variant='contained'
                  style={{
                    color: 'white',
                    backgroundColor: '#29707A',
                    borderRadius: '0px',
                    textTransform: 'none',
                    fontSize: '14px',
                    boxShadow: 'none',
                    padding: '6px 12px 6px 12px',
                  }}
                  onClick={() => {
                    a.onClick(checkedItems)
                    setNone()
                  }}
                  size='large'
                >
                  {a.label}
                </Button>
              </Tooltip>
            </Grid>
          ))}
      </Grid>
    )
  }

  function getItemIcon(item: CheckboxItemsProps): JSX.Element | undefined {
    if (!checkboxActions) return item.icon

    const checked = checkedItems.includes(item.id)

    return (
      <Checkbox
        checked={checked}
        data-cy={'item-' + item.id}
        disabled={item.selectable === false}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()

          if (checked) {
            setCheckedItems(checkedItems.filter((id) => id !== item.id))
          } else {
            setCheckedItems([...checkedItems, item.id])
          }
        }}
      />
    )
  }

  function getItems(): CheckboxItemsProps[] | PaginatedListItemProps[] {
    if (itemsHaveID(items)) {
      return items.map((item) => ({ ...item, icon: getItemIcon(item) }))
    }

    return items
  }

  let headervalue: JSX.Element

  if (tabUrl.includes('alerts')) {
    if (isGAControlPlane) {
      headervalue = (
        <React.Fragment>
          <Grid
            container
            item
            xs={12}
            justifyContent='flex-end'
            alignItems='center'
          >
            {renderActions()}
            {secondaryActions}
            {!noSearch && (
              <Grid
                item
                className={classes.search}
                style={{ paddingLeft: '0px' }}
              >
                <Search endAdornment={searchAdornment} />
              </Grid>
            )}
            <Button
              variant='contained'
              aria-label='Create Incident'
              style={{
                textTransform: 'none',
                fontSize: '14px',
                boxShadow: 'none',
                padding: '7px 12px 7px 12px',
                margin: '0px',
                color: '#042315',
                background: '#4CDD84',
                borderRadius: '0px',
              }}
              color='inherit'
              size='large'
              onClick={() => setShowCreate(true)}
              endIcon={<ControlPointIcon />}
            >
              Create New Incident
            </Button>
            {showCreate && (
              <CreateAlertDialog
                onClose={() => setShowCreate(false)}
                // serviceID= {serviceID}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Card>
              {listHeader}
              <PaginatedList key={urlKey} {...listProps} items={getItems()} />
            </Card>
          </Grid>
        </React.Fragment>
      )
    } else {
      headervalue = (
        <React.Fragment>
          <Grid
            container
            item
            xs={12}
            justifyContent='flex-end'
            alignItems='center'
          >
            {renderActions()}
            {secondaryActions}
            {!noSearch && (
              <Grid
                item
                className={classes.search}
                style={{ paddingLeft: '0px' }}
              >
                <Search endAdornment={searchAdornment} />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Card>
              {listHeader}
              <PaginatedList key={urlKey} {...listProps} items={getItems()} />
            </Card>
          </Grid>
        </React.Fragment>
      )
    }
  } else {
    if (isGAControlPlane) {
      headervalue = (
        <React.Fragment>
          <Grid
            container
            item
            xs={12}
            justifyContent='flex-end'
            alignItems='center'
          >
            {renderActions()}
            {secondaryActions}
            {!noSearch && (
              <Grid
                item
                className={classes.search}
                style={{ paddingLeft: '0px', marginRight: 'auto' }}
              >
                <Search endAdornment={searchAdornment} />
              </Grid>
            )}
            {createFormvalue && (
              <Button
                variant='contained'
                aria-label={`Create ${createLabel}`}
                style={{
                  textTransform: 'none',
                  fontSize: '14px',
                  boxShadow: 'none',
                  padding: '7px 12px 7px 12px',
                  margin: '0px',
                  color: '#042315',
                  backgroundColor: '#4CDD84',
                  borderRadius: '0px',
                }}
                color='inherit'
                size='large'
                onClick={() => setCreate(true)}
                endIcon={<ControlPointIcon />}
              >{`Create ${createLabel}`}</Button>
            )}
            {create &&
              React.cloneElement(createFormvalue, {
                onClose: () => setCreate(false),
              })}
          </Grid>
          <Grid item xs={12}>
            <Card>
              {listHeader}
              <PaginatedList key={urlKey} {...listProps} items={getItems()} />
            </Card>
          </Grid>
        </React.Fragment>
      )
    } else {
      headervalue = (
        <React.Fragment>
          <Grid
            container
            item
            xs={12}
            justifyContent='flex-end'
            alignItems='center'
          >
            {renderActions()}
            {secondaryActions}
            {!noSearch && (
              <Grid
                item
                className={classes.search}
                style={{ paddingLeft: '0px', marginRight: 'auto' }}
              >
                <Search endAdornment={searchAdornment} />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Card>
              {listHeader}
              <PaginatedList key={urlKey} {...listProps} items={getItems()} />
            </Card>
          </Grid>
        </React.Fragment>
      )
    }
  }
  return headervalue
}
