/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import { PropTypes as p } from 'prop-types'
import { FormContainer, FormField } from '../forms'
import { UserSelect } from '../selection'

export default function UserForm(props) {
  return (
    <FormContainer {...props}>
      <FormField
        component={UserSelect}
        disabled={false}
        fieldName='users'
        fullWidth
        label='Select User(s)'
        multiple
        name='users'
        required
        value={props.value.users}
      />
    </FormContainer>
  )
}

UserForm.propTypes = {
  errors: p.array,
  onChange: p.func,
  disabled: p.bool,
  value: p.shape({
    users: p.arrayOf(p.string),
  }).isRequired,
}
