/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { FormContainer, FormField } from '../forms'
import UserContactMethodSelect from './UserContactMethodSelect'
import { FieldError } from '../util/errutil'

interface CreateNotificationRule {
  contactMethodID: string
  delayMinutes: number
}

interface UserNotificationRuleFormProps {
  userID: string

  value: CreateNotificationRule

  errors: Error[] | FieldError[]

  onChange: (value: CreateNotificationRule) => void

  disabled: boolean
}

interface Error {
  field: 'delayMinutes' | 'contactMethodID'
  message: string
}

export default function UserNotificationRuleForm(
  props: UserNotificationRuleFormProps,
): JSX.Element {
  const { userID, ...other } = props
  return (
    <FormContainer {...other} optionalLabels>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <FormField
            fullWidth
            name='contactMethodID'
            label='Contact Method'
            userID={userID}
            required
            component={UserContactMethodSelect}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormField
            fullWidth
            name='delayMinutes'
            required
            label='Delay (minutes)'
            type='number'
            min={0}
            max={9000}
            component={TextField}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
