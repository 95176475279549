/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
// safeURL will determine if a url is safe for linking.
//
// It tries to determine if the label is misleading.
export function safeURL(url: string, label: string): boolean {
  if (url.startsWith('mailto:')) {
    const email = url.substr(7)
    return email === label && email.includes('@')
  }

  if (url.startsWith('tel:')) {
    const phone = url.substr(4)
    return phone === label && /^\+?[\d\- ]+$/.test(phone)
  }

  // handle http protocols
  if (!/https?:\/\//.test(url)) return false // require absolute URLs
  if (!/[./]/.test(label)) return true // don't consider it a path/url without slashes or periods
  if (url.startsWith(label)) return true // if it matches the beginning, then it's fine
  if (url.replace(/^https?:\/\//, '').startsWith(label)) return true // same prefix without protocol
  if (url.replace(/^https?:\/\//, '').startsWith('www.' + label)) return true // same prefix without protocol

  return false
}
