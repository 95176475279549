/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import { pathPrefix } from '../env'

export const AUTH_LOGOUT = 'AUTH_LOGOUT'

// authLogout will update the user's auth state.
//
// If true is passed as an argument, a request to end
// the current session will be first made to the backend.
//
// AUTH_LOGOUT will be dispatched if, and after, the request completes.
export function authLogout(performFetch = false) {
  const payload = { type: AUTH_LOGOUT }
  if (!performFetch) return payload
  return (dispatch) =>
    fetch(pathPrefix + '/api/v2/identity/logout', {
      credentials: 'same-origin',
      method: 'POST',
    }).then(dispatch(payload))
}
