/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useContext, useState } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import makeStyles from '@mui/styles/makeStyles'
import { gql } from '@apollo/client'
import FlatList from '../lists/FlatList'
import Query from '../util/Query'
import OtherActions from '../util/OtherActions'
import { ArrowBack as BackIcon } from '@mui/icons-material'
import AppLink from '../util/AppLink'
import '../util/services.css'
import IconButton from '@mui/material/IconButton'

import ServiceLabelSetDialog from './ServiceLabelCreateDialog'
import ServiceLabelEditDialog from './ServiceLabelEditDialog'
import ServiceLabelDeleteDialog from './ServiceLabelDeleteDialog'
import Button from '@mui/material/Button'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { userContext } from '../main/App'

const query = gql`
  query ($serviceID: ID!) {
    service(id: $serviceID) {
      id # need to tie the result to the correct record
      labels {
        key
        value
      }
    }
  }
`

const sortItems = (a, b) => {
  if (a.key.toLowerCase() < b.key.toLowerCase()) return -1
  if (a.key.toLowerCase() > b.key.toLowerCase()) return 1
  if (a.key < b.key) return -1
  if (a.key > b.key) return 1
  return 0
}

const useStyles = makeStyles({ spacing: { marginBottom: 96 } })

export default function ServiceLabelList({ serviceID }) {
  const [create, setCreate] = useState(false)
  const [editKey, setEditKey] = useState(null)
  const [deleteKey, setDeleteKey] = useState(null)
  const classes = useStyles()

  let showCreateButton
  const plane = useContext(userContext)
  const isGAControlPlane = plane !== 'serviceplane'

  function renderList(labels) {
    if (isGAControlPlane) {
      const items = (labels || [])
        .slice()
        .sort(sortItems)
        .map((label) => ({
          title: label.key,
          subText: label.value,
          secondaryAction: (
            <OtherActions
              actions={[
                {
                  label: 'Edit',
                  onClick: () => setEditKey(label.key),
                },
                {
                  label: 'Delete',
                  onClick: () => setDeleteKey(label.key),
                },
              ]}
            />
          ),
        }))

      return (
        <FlatList
          data-cy='label-list'
          style={{ marginTop: '30px' }}
          emptyMessage='No labels exist for this service.'
          items={items}
        />
      )
    }
    const items = (labels || [])
      .slice()
      .sort(sortItems)
      .map((label) => ({
        title: label.key,
        subText: label.value,
      }))

    return (
      <FlatList
        data-cy='label-list'
        emptyMessage='No labels exist for this service.'
        items={items}
      />
    )
  }

  function renderQuery() {
    return (
      <Query
        query={query}
        variables={{ serviceID }}
        render={({ data }) => renderList(data.service.labels)}
      />
    )
  }

  // return (
  if (isGAControlPlane) {
    showCreateButton = (
      <React.Fragment>
        <Grid item xs={12} className={classes.spacing}>
          <IconButton
            className='ser-ib'
            size='large'
            style={{ paddingLeft: '0px' }}
          >
            <AppLink to={`/services/${serviceID}`}>
              <BackIcon className='ser-ic' />
            </AppLink>
          </IconButton>
          <Button
            variant='contained'
            aria-label='Add Label'
            style={{
              textTransform: 'none',
              fontSize: '14px',
              boxShadow: 'none',
              padding: '7px 12px 7px 12px',
              margin: '0px',
              color: '#042315',
              background: '#4CDD84',
              borderRadius: '0px',
              position: 'absolute',
              right: '45px',
            }}
            color='inherit'
            size='large'
            onClick={() => setCreate(true)}
            endIcon={<ControlPointIcon />}
          >
            Add Label
          </Button>
          <Card>
            <CardContent>{renderQuery()}</CardContent>
          </Card>
        </Grid>
        {create && (
          <ServiceLabelSetDialog
            serviceID={serviceID}
            onClose={() => setCreate(false)}
          />
        )}
        {editKey && (
          <ServiceLabelEditDialog
            serviceID={serviceID}
            labelKey={editKey}
            onClose={() => setEditKey(null)}
          />
        )}
        {deleteKey && (
          <ServiceLabelDeleteDialog
            serviceID={serviceID}
            labelKey={deleteKey}
            onClose={() => setDeleteKey(null)}
          />
        )}
      </React.Fragment>
    )
  } else {
    showCreateButton = (
      <React.Fragment>
        <Grid item xs={12} className={classes.spacing}>
          <IconButton
            className='ser-ib'
            size='large'
            style={{ paddingLeft: '0px' }}
          >
            <AppLink to={`/services/${serviceID}`}>
              <BackIcon className='ser-ic' />
            </AppLink>
          </IconButton>
          <Card>
            <CardContent>{renderQuery()}</CardContent>
          </Card>
        </Grid>
        {create && (
          <ServiceLabelSetDialog
            serviceID={serviceID}
            onClose={() => setCreate(false)}
          />
        )}
        {editKey && (
          <ServiceLabelEditDialog
            serviceID={serviceID}
            labelKey={editKey}
            onClose={() => setEditKey(null)}
          />
        )}
        {deleteKey && (
          <ServiceLabelDeleteDialog
            serviceID={serviceID}
            labelKey={deleteKey}
            onClose={() => setDeleteKey(null)}
          />
        )}
      </React.Fragment>
    )
  }
  return showCreateButton
}
