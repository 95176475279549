/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useState, ReactElement, useContext } from 'react'
import { useQuery, gql } from 'urql'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import FlatList from '../lists/FlatList'
import HeartbeatMonitorCreateDialog from './HeartbeatMonitorCreateDialog'
import makeStyles from '@mui/styles/makeStyles'
import HeartbeatMonitorEditDialog from './HeartbeatMonitorEditDialog'
import HeartbeatMonitorDeleteDialog from './HeartbeatMonitorDeleteDialog'
import OtherActions from '../util/OtherActions'
import HeartbeatMonitorStatus from './HeartbeatMonitorStatus'
import CopyText from '../util/CopyText'
import Spinner from '../loading/components/Spinner'
import { GenericError } from '../error-pages'
import { HeartbeatMonitor } from '../../schema'
import Button from '@mui/material/Button'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { ArrowBack as BackIcon } from '@mui/icons-material'
import AppLink from '../util/AppLink'
import '../util/services.css'
import IconButton from '@mui/material/IconButton'
import { userContext } from '../main/App'

// generates a single alert if a POST is not received before the timeout
const HEARTBEAT_MONITOR_DESCRIPTION =
  'Heartbeat monitors create an alert if no heartbeat is received (a POST request) before the configured timeout.'

const query = gql`
  query monitorQuery($serviceID: ID!) {
    service(id: $serviceID) {
      id # need to tie the result to the correct record
      heartbeatMonitors {
        id
        name
        timeoutMinutes
        lastState
        lastHeartbeat
        href
      }
    }
  }
`

const useStyles = makeStyles(() => ({
  text: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  spacing: {
    marginBottom: 96,
  },
}))

const sortItems = (a: HeartbeatMonitor, b: HeartbeatMonitor): number => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0
}

export default function HeartbeatMonitorList(props: {
  serviceID: string
}): JSX.Element {
  const classes = useStyles()
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [showEditDialogByID, setShowEditDialogByID] = useState<string | null>(
    null,
  )
  const [showDeleteDialogByID, setShowDeleteDialogByID] = useState<
    string | null
  >(null)

  const [{ data, fetching, error }] = useQuery({
    query,
    variables: { serviceID: props.serviceID },
  })

  if (fetching && !data) return <Spinner />
  if (error) return <GenericError error={error.message} />

  let showCreateButton
  const plane = useContext(userContext)
  const isGAControlPlane: boolean = plane !== 'serviceplane'

  function renderList(monitors: HeartbeatMonitor[]): ReactElement {
    if (isGAControlPlane) {
      const items = (monitors || [])
        .slice()
        .sort(sortItems)
        .map((monitor) => ({
          icon: (
            <HeartbeatMonitorStatus
              lastState={monitor.lastState}
              lastHeartbeat={monitor.lastHeartbeat}
            />
          ),
          title: monitor.name,
          subText: (
            <React.Fragment>
              {`Timeout: ${monitor.timeoutMinutes} minute${
                monitor.timeoutMinutes > 1 ? 's' : ''
              }`}
              <br />
              <CopyText title='Copy URL' value={monitor.href} asURL />
            </React.Fragment>
          ),
          secondaryAction: (
            <OtherActions
              actions={[
                {
                  label: 'Edit',
                  onClick: () => setShowEditDialogByID(monitor.id),
                },
                {
                  label: 'Delete',
                  onClick: () => setShowDeleteDialogByID(monitor.id),
                },
              ]}
            />
          ),
        }))

      return (
        <FlatList
          data-cy='monitors'
          style={{ marginTop: '30px' }}
          emptyMessage='No heartbeat monitors exist for this service.'
          headerNote={HEARTBEAT_MONITOR_DESCRIPTION}
          items={items}
        />
      )
    }
    const items = (monitors || [])
      .slice()
      .sort(sortItems)
      .map((monitor) => ({
        icon: (
          <HeartbeatMonitorStatus
            lastState={monitor.lastState}
            lastHeartbeat={monitor.lastHeartbeat}
          />
        ),
        title: monitor.name,
        subText: (
          <React.Fragment>
            {`Timeout: ${monitor.timeoutMinutes} minute${
              monitor.timeoutMinutes > 1 ? 's' : ''
            }`}
            <br />
            <CopyText title='Copy URL' value={monitor.href} asURL />
          </React.Fragment>
        ),
      }))

    return (
      <FlatList
        data-cy='monitors'
        emptyMessage='No heartbeat monitors exist for this service.'
        headerNote={HEARTBEAT_MONITOR_DESCRIPTION}
        items={items}
      />
    )
  }

  // return (
  if (isGAControlPlane) {
    showCreateButton = (
      <React.Fragment>
        <Grid item xs={12} className={classes.spacing}>
          <IconButton
            className='ser-ib'
            size='large'
            style={{ paddingLeft: '0px' }}
          >
            <AppLink to={`/services/${props.serviceID}`}>
              <BackIcon className='ser-ic' />
            </AppLink>
          </IconButton>
          <Button
            variant='contained'
            aria-label='Create Heartbeat Monitor'
            style={{
              textTransform: 'none',
              fontSize: '14px',
              boxShadow: 'none',
              padding: '7px 12px 7px 12px',
              margin: '0px',
              color: '#042315',
              background: '#4CDD84',
              borderRadius: '0px',
              position: 'absolute',
              right: '45px',
            }}
            color='inherit'
            size='large'
            onClick={() => setShowCreateDialog(true)}
            endIcon={<ControlPointIcon />}
          >
            Create Heartbeat Monitor
          </Button>
          <Card>
            <CardContent>
              {renderList(data.service.heartbeatMonitors)}
            </CardContent>
          </Card>
        </Grid>
        {showCreateDialog && (
          <HeartbeatMonitorCreateDialog
            serviceID={props.serviceID}
            onClose={() => setShowCreateDialog(false)}
          />
        )}
        {showEditDialogByID && (
          <HeartbeatMonitorEditDialog
            monitorID={showEditDialogByID}
            onClose={() => setShowEditDialogByID(null)}
          />
        )}
        {showDeleteDialogByID && (
          <HeartbeatMonitorDeleteDialog
            monitorID={showDeleteDialogByID}
            onClose={() => setShowDeleteDialogByID(null)}
          />
        )}
      </React.Fragment>
    )
  } else {
    showCreateButton = (
      <React.Fragment>
        <Grid item xs={12} className={classes.spacing}>
          <IconButton
            className='ser-ib'
            size='large'
            style={{ paddingLeft: '0px' }}
          >
            <AppLink to={`/services/${props.serviceID}`}>
              <BackIcon className='ser-ic' />
            </AppLink>
          </IconButton>
          <Card>
            <CardContent>
              {renderList(data.service.heartbeatMonitors)}
            </CardContent>
          </Card>
        </Grid>
      </React.Fragment>
    )
  }
  return showCreateButton
}
