/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import { DateTime } from 'luxon'
import { useURLParams } from '../../actions'
import { getStartOfWeek } from '../../util/luxon-helpers'

interface CalendarNavParams {
  weekly: boolean
  start: string
}
interface CalendarNavigation extends CalendarNavParams {
  setParams: (val: Partial<CalendarNavParams>) => void
}

export function useCalendarNavigation(): CalendarNavigation {
  const [_params] = useURLParams({ weekly: false })
  const [params, setParams] = useURLParams({
    weekly: false as boolean,
    start: _params.weekly
      ? getStartOfWeek().toISODate()
      : DateTime.now().startOf('month').toISODate(),
  })

  return {
    weekly: params.weekly,
    start: params.start,
    setParams,
  }
}
