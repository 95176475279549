/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 * You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 * The code base belongs to Data & AI Services - ADAI Practice
 * Please refer to the agreement/license for specific  governing permissions and
 * limitations under the License.
 * Code Author and Ownership:
 *
 * Prabhu Parthasarathy ,
 * Sr. Product Architect,
 * Email: prabhu.parthasarathy@kyndryl.com ,
 */
import React, { useState } from 'react'
import { useQuery, gql } from 'urql'
import DetailsPage from '../details/DetailsPage'
import StatusUpdateNotification from './UserStatusUpdatePreference'
import { UserAvatar } from '../util/avatars'
import UserContactMethodList from './UserContactMethodList'
import UserNotificationRuleList from './UserNotificationRuleList'
import { Grid } from '@mui/material'
import UserContactMethodCreateDialog from './UserContactMethodCreateDialog'
import UserNotificationRuleCreateDialog from './UserNotificationRuleCreateDialog'
import UserContactMethodVerificationDialog from './UserContactMethodVerificationDialog'
import _ from 'lodash'
import Spinner from '../loading/components/Spinner'
import { GenericError, ObjectNotFound } from '../error-pages'
import { useConfigValue, useSessionInfo } from '../util/RequireConfig'
import UserEditDialog from './UserEditDialog'
import UserDeleteDialog from './UserDeleteDialog'
import { QuerySetFavoriteButton } from '../util/QuerySetFavoriteButton'
import { EscalationPolicyStep } from '../../schema'
import '../util/rotations.css'
import Button from '@mui/material/Button'
import ControlPointIcon from '@mui/icons-material/ControlPoint'

const userQuery = gql`
  query userInfo($id: ID!) {
    user(id: $id) {
      id
      role
      name
      email
      contactMethods {
        id
      }
      onCallSteps {
        id
        escalationPolicy {
          id
          assignedTo {
            id
            name
          }
        }
      }
    }
  }
`

const profileQuery = gql`
  query profileInfo($id: ID!) {
    user(id: $id) {
      id
      role
      name
      email
      contactMethods {
        id
      }
      onCallSteps {
        id
        escalationPolicy {
          id
          assignedTo {
            id
            name
          }
        }
      }
      sessions {
        id
      }
    }
  }
`

function serviceCount(onCallSteps: EscalationPolicyStep[] = []): number {
  const svcs: { [Key: string]: boolean } = {}
  ;(onCallSteps || []).forEach((s) =>
    (s?.escalationPolicy?.assignedTo || []).forEach(
      (svc) => (svcs[svc.id] = true),
    ),
  )

  return Object.keys(svcs).length
}

export default function UserDetails(props: {
  userID: string
  readOnly: boolean
}): JSX.Element {
  const userID = props.userID
  const {
    userID: currentUserID,
    isAdmin,
    ready: isSessionReady,
  } = useSessionInfo()
  const [disclaimer] = useConfigValue('General.NotificationDisclaimer')
  const [createCM, setCreateCM] = useState(false)
  const [createNR, setCreateNR] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showVerifyDialogByID, setShowVerifyDialogByID] = useState<
    string | null | undefined
  >(null)
  const [showUserDeleteDialog, setShowUserDeleteDialog] = useState(false)

  const [{ data, fetching: isQueryLoading, error }] = useQuery({
    query: isAdmin || userID === currentUserID ? profileQuery : userQuery,
    variables: { id: userID },
    pause: !userID,
  })

  const loading = !isSessionReady || isQueryLoading

  if (error) return <GenericError error={error.message} />
  if (!_.get(data, 'user.id')) return loading ? <Spinner /> : <ObjectNotFound />

  const user = _.get(data, 'user')
  const svcCount = serviceCount(user.onCallSteps)
  const sessCount = user?.sessions?.length ?? 0

  // const disableNR = user.contactMethods.length === 0

  const links = [
    {
      label: 'On-Call Assignments',
      url: 'on-call-assignments',
      subText: svcCount
        ? `On-call for ${svcCount} service${svcCount > 1 ? 's' : ''}`
        : 'Not currently on-call',
    },
  ]

  if (userID === currentUserID) {
    links.push({
      label: 'Schedule Calendar Subscriptions',
      url: 'schedule-calendar-subscriptions',
      subText: 'Manage schedules you have subscribed to',
    })
  }

  if (isAdmin || userID === currentUserID) {
    links.push({
      label: 'Active Sessions',
      url: 'sessions',
      subText: `${sessCount || 'No'} active session${
        sessCount === 1 ? '' : 's'
      }`,
    })
  }

  /**
   *
   * @returns
   * Edit, Delete, Add Contact and Notification buttons will appear
   */
  function getUserMenuOptions(): any {
    return (
      <div
        style={{
          display: 'flex',
          float: 'right',
          position: 'relative',
          top: '9rem',
        }}
      >
        <Button
          className='onCallNotification edi'
          onClick={() => setCreateCM(true)}
          endIcon={<ControlPointIcon style={{ marginTop: '4px' }} />}
          style={{ position: 'absolute', right: '60rem', padding: '7px 12px' }}
        >
          Add Contact Method
        </Button>
        <Button
          className='onCallNotification edi'
          onClick={() => setCreateNR(true)}
          endIcon={<ControlPointIcon style={{ marginTop: '4px' }} />}
          style={{ position: 'absolute', right: '46rem', padding: '7px 12px' }}
        >
          Add Notification Rule
        </Button>
        <Button
          className='but del'
          onClick={() => setShowUserDeleteDialog(true)}
        >
          Delete
        </Button>
        <Button className='but edi' onClick={() => setShowEdit(true)}>
          Edit
        </Button>
      </div>
    )
  }

  return (
    <Grid container spacing={2}>
      <div style={{ marginLeft: '20px', marginRight: '5px', width: '100%' }}>
        <div>
          <div>{getUserMenuOptions()}</div>
          <React.Fragment>
            {showEdit && (
              <UserEditDialog
                onClose={() => setShowEdit(false)}
                userID={userID}
                role={user.role}
              />
            )}
            {showUserDeleteDialog && (
              <UserDeleteDialog
                userID={userID}
                onClose={() => setShowUserDeleteDialog(false)}
              />
            )}
            {createCM && (
              <UserContactMethodCreateDialog
                userID={userID}
                disclaimer={disclaimer?.toString()}
                onClose={(contactMethodID) => {
                  setCreateCM(false)
                  setShowVerifyDialogByID(contactMethodID)
                }}
              />
            )}
            {showVerifyDialogByID && (
              <UserContactMethodVerificationDialog
                contactMethodID={showVerifyDialogByID}
                onClose={() => setShowVerifyDialogByID(null)}
              />
            )}
            {createNR && (
              <UserNotificationRuleCreateDialog
                userID={userID}
                onClose={() => setCreateNR(false)}
              />
            )}
            <DetailsPage
              avatar={<UserAvatar userID={userID} />}
              title={user.name + (svcCount ? ' (On-Call)' : '')}
              subheader={user.email}
              pageContent={
                <Grid container spacing={2}>
                  <UserContactMethodList
                    userID={userID}
                    readOnly={props.readOnly}
                  />
                  <UserNotificationRuleList
                    userID={userID}
                    readOnly={props.readOnly}
                  />
                </Grid>
              }
              primaryActions={
                props.readOnly
                  ? []
                  : [
                      <StatusUpdateNotification
                        key='primary-action-status-updates'
                        userID={userID}
                      />,
                    ]
              }
              secondaryActions={
                isAdmin
                  ? [
                      <QuerySetFavoriteButton
                        key='secondary-action-favorite'
                        id={userID}
                        type='user'
                      />,
                    ]
                  : [
                      <QuerySetFavoriteButton
                        key='secondary-action-favorite'
                        id={userID}
                        type='user'
                      />,
                    ]
              }
              links={links}
            />
          </React.Fragment>
        </div>
      </div>
    </Grid>
  )
}
